<div class="container">

  <form
  data-amplify-form
  class="centered-content amplifyBorders" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
    <h3 class="form-group">Wijzigen Wachtwoord</h3>
    <div class="form-group">
      <div class="amplify-field amplify-flex amplify-passwordfield amplify-textfield">
        <label class="amplify-label" for="oldPassword">Oud Wachtwoord </label>
        <div class="amplify-field-group amplify-flex"><div class="amplify-field-group__field-wrapper">
          <input class="amplify-field-group__control amplify-field__show-password amplify-input"
          id="oldPassword" type="password" formControlName="oldPassword" type="password" name="confirm_password" placeholder="wachtwoord" required="" autocomplete="new-password" aria-invalid="true"></div>
          <div class="amplify-field-group__outer-end">
            <button amplify-button="" role="switch" (click)="toggle('oldPassword')"
            class="amplify-field-group__control amplify-input amplify-button amplify-button--default amplify-button--medium"
            aria-checked="false" aria-label="Toon wachtwoord" type="button"
            data-fullwidth="false" data-size="medium" data-variation="default" style="font-weight: normal;">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" class="amplify-icon">
                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="amplify-field amplify-flex amplify-passwordfield amplify-textfield">
        <label class="amplify-label" for="newPassword"> Nieuw Wachtwoord </label>
        <div class="amplify-field-group amplify-flex"><div class="amplify-field-group__field-wrapper">
          <input class="amplify-field-group__control amplify-field__show-password amplify-input" (input)="checkPasswordPolicy($event)"
          id="newPassword" type="password" formControlName="newPassword" type="password" name="confirm_password" placeholder="wachtwoord" required="" autocomplete="new-password" aria-invalid="true"></div>
          <div class="amplify-field-group__outer-end">
            <button amplify-button="" role="switch" (click)="toggle('newPassword')"
            class="amplify-field-group__control amplify-input amplify-button amplify-button--default amplify-button--medium"
            aria-checked="false" aria-label="Toon wachtwoord" type="button"
            data-fullwidth="false" data-size="medium" data-variation="default" style="font-weight: normal;">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" class="amplify-icon">
                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="errorMessage" class="error">{{ errorMessage }}</div>

    <div class="form-group buttons">
      <button type="submit" class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" *ngIf="passwordPolicyOk">Wijzigen</button>
      <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--destructive" (click)="cancel()">Annuleren</button>
    </div>
  </form>
</div>


