import { Auth } from 'aws-amplify'
import { log, logDebug, logError, logInfo, logWarning } from '../../log'
import { isMAPPING_USER } from "../../main"
import { showSpinner } from '../../spinner'
import { TOAST_LEVELS, showToast } from "../../toast"
import { clearRedirectUriOnStartup, deleteCookiesWithPrefix, isAndroid, isSafariOnIPadOrMac, reInitAmplify } from '../../utils'
import { IPool, PARAMS, POOL_NAMES, findPoolById } from "../PARAMS"
import { getPool, logoutFromAmplifyState, POOL_ID_KEY, tryGetTokenFromAmplifyState } from "../amplify/amplify_state"
import { STORAGE } from '../server-storage'
import { RELOAD_TIMES } from '../safeGuardInfiniteReload'

const STATES = {
    "init": "Initialisatie uitlog process.",
    "amplify":  "Log uit Amplify.",
    "cognito": "Log uit Cognito."
}

const STATES_SEQUENCE = [
    STATES.init,
    STATES.amplify,
    STATES.cognito
]

export const LOGOUT_STEP_KEY = "logout-step"
export const LOGOUT_FROM_POOL_ID_KEY = "logout-from-pool-id"
export const THIRD_PARTY_LOGOUT_URL_KEY = "third-party-logout-url"
export const LOGOUT_IDTOKEN_KEY = "logout-idtoken-key"
export const LOGOUT_REFRESHTOKEN_KEY = "logout-refreshtoken-key"

const logoutFromCognito = async (pool: IPool) => {
    const cleanup = () => {
        delete localStorage[LOGOUT_REFRESHTOKEN_KEY]
        delete localStorage[LOGOUT_IDTOKEN_KEY]
    }
    if ([POOL_NAMES.LEER_ID, POOL_NAMES.TEST_LEER_ID].includes(pool.NAME)) {
        const body = JSON.stringify({
            "token": localStorage[LOGOUT_REFRESHTOKEN_KEY]
        })
        /*const result = await fetch(`${PARAMS.LEERID_LAMBDA}/revoke`, {
            method: "DELETE",
            body
        })
        const response = await result.json()
        logDebug(response)*/
        cleanup() 
        reInitAmplify()
        return
    }
    const usp = new URLSearchParams()
    usp.append("client_id", pool?.CLIENT_ID)
    //if (![POOL_NAMES.LEER_ID, POOL_NAMES.TEST_LEER_ID].includes(pool.NAME)) {
        usp.append("logout_uri", location.origin + "/logout")
        usp.append("response_type", "code")
    /*} else {
        usp.append("state", "eyJzd2l0Y2hfaWQiOiB0cnVlfQ==")
    }*/
    //if (localStorage[LOGOUT_IDTOKEN_KEY] ) usp.append("id_token_hint", localStorage[LOGOUT_IDTOKEN_KEY] )
    //const url = `${pool?.DOMAIN}/logout?${usp}`
    cleanup()
    location.href = `${pool?.DOMAIN}/logout?client_id=${pool?.CLIENT_ID}&logout_uri=${location.origin + "/logout"}&response_type=code`
    //location.href = url
}

const transitionToNextState = (): string => {
    const last_completed_step = localStorage.getItem(LOGOUT_STEP_KEY)  
    if (last_completed_step) {
        const index = STATES_SEQUENCE.indexOf(last_completed_step)
        const nextStep = STATES_SEQUENCE[index + 1]
        if (nextStep) {
            log("transition to nextStep", nextStep)
            localStorage.setItem(LOGOUT_STEP_KEY, nextStep)   
            return nextStep
        } else {
            log("No nextStep available, last completed : ", last_completed_step)
        }

        return ""
    }

    logError("no last completed step ...")
    return ""
}

const processCurrentState = async(): Promise<any | void> => {
    return new Promise<any | void>(async (resolve, reject) => {
        try {
            let current_state = localStorage.getItem(LOGOUT_STEP_KEY)  
            if (!current_state) current_state = STATES.init   
            logInfo("current state", current_state)
            showSpinner(`Verwerken stap : ${current_state }.` )
            switch(current_state) {
                case STATES.init:
                    const poolID = localStorage.getItem(LOGOUT_FROM_POOL_ID_KEY) || localStorage.getItem("POOL_ID")
                    let currentPool: IPool | undefined
                    if (poolID) currentPool = findPoolById(poolID)
                    if (!poolID || !currentPool) {
                        throw new Error("Kan de huidige pool niet bepalen, mogelijks bent u reeds uitgelogd. Probeer het eens opnieuw door te pagina te herladen.")
                    }
                    localStorage.removeItem(POOL_ID_KEY)
                    localStorage.setItem(LOGOUT_STEP_KEY, STATES.init)
                    switch(currentPool?.NAME) {
                        case POOL_NAMES.LEER_ID: 
                        case POOL_NAMES.TEST_LEER_ID: // INTENTIONAL FALLTHROUGH
                            localStorage.setItem(THIRD_PARTY_LOGOUT_URL_KEY, currentPool.IDP_ENDPOINT + "/op/v1/logout")
                            break
                        case POOL_NAMES.SMARTSCHOOL:
                        case POOL_NAMES.TEST_SMARTSCHOOL:
                            localStorage.setItem(THIRD_PARTY_LOGOUT_URL_KEY, currentPool.IDP_ENDPOINT + "/logout")  
                            break
                        case POOL_NAMES.GOOGLE:
                        case POOL_NAMES.TEST_GOOGLE:                            
                            localStorage.setItem(THIRD_PARTY_LOGOUT_URL_KEY, currentPool.IDP_ENDPOINT + "?logout&hl=en")  
                            break
                    }
                    current_state = transitionToNextState()
                
                    logInfo(`poolname: ${currentPool?.NAME}, IDP ENDPOINT set to ${localStorage.getItem(THIRD_PARTY_LOGOUT_URL_KEY)}`)
                    break
                case STATES.cognito:
                    const oldPool = findPoolById(localStorage.getItem(LOGOUT_FROM_POOL_ID_KEY) as string)
                    if (!oldPool) {
                        const err = "Oude pool niet gevonden om uit te loggen van Cognito."
                        logWarning(err)
                        reject(err)
                        return
                    }  
                    current_state = transitionToNextState()
                    /*if ([POOL_NAMES.LEER_ID, POOL_NAMES.TEST_LEER_ID].includes(oldPool.NAME)) {
                        const delete_result = 
                        await fetch(`${PARAMS.LEERID_LAMBDA}?token=${(await getCurrentAmplifyUser())?.signInUserSession?.accessToken?.jwtToken}`, {
                            method: "DELETE",
                        })
                        const response = await delete_result.json()
                        if (!response.success) {
                            logError("Failed to log out from leerid lambda", response)
                        }
                    }*/
                    localStorage.removeItem(LOGOUT_STEP_KEY)
                    localStorage.removeItem(LOGOUT_FROM_POOL_ID_KEY)
                    
                    if (isSafariOnIPadOrMac() || isAndroid()) {
                        deleteCookiesWithPrefix()
                    }
                    
                    clearRedirectUriOnStartup()
                    if (![POOL_NAMES.E_DUCATE_ME, POOL_NAMES.TEST_E_DUCATE_ME].includes(oldPool?.NAME as string)) {                   
                        await logoutFromCognito(oldPool as IPool)
                    } else {
                        reInitAmplify()
                    }
                    break   
                case STATES.amplify:
                    current_state = transitionToNextState()
                    await Auth.signOut()
                    logoutFromAmplifyState()
                    break        
            }
    
            resolve(current_state)
        } catch(e) {
            reject(e)
        }
    })
}

export const logout = async () => {
   if (isMAPPING_USER()) return

   try {
        let nextState = ""
        do {
            nextState = await processCurrentState()
            localStorage.removeItem(RELOAD_TIMES)
        } while (nextState)
    } catch(e) {
      logError(e)
      const error = `Er is een fout opgetreden tijdens het uitloggen: ${e}.`
      showSpinner(error, true)
      showToast(error, TOAST_LEVELS.ERROR, "Fout")
    } 
}

export const initLogout = () => {
    let pool = getPool()
    localStorage.setItem(LOGOUT_FROM_POOL_ID_KEY, pool?.USER_POOL_ID as string)
    let token = tryGetTokenFromAmplifyState(STORAGE, ".idToken")
    if (token) {
        localStorage[LOGOUT_IDTOKEN_KEY] = token
        token = tryGetTokenFromAmplifyState(STORAGE, ".refreshToken")
        if (token) {
            localStorage[LOGOUT_REFRESHTOKEN_KEY] = token
        }
    }
}


