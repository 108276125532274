import { Hub } from '@aws-amplify/core'
import { Subject, Subscription } from 'rxjs'
import { IJWT } from '../IJWT'
import { log, logDebug, logError, logInfo, logWarning } from '../log'
import { QS_REDIRECT_URI, REDIRECT_URL_ON_STARTUP, USER_MAPPING_INFO_KEY } from "../main"
import { customSignInWithSmartSchool } from '../pools/smartschool'
import { showSpinner } from '../spinner'
import { TOAST_LEVELS, showToast } from "../toast"
import { IPool, PARAMS, POOL_NAMES, findPoolByName } from "./PARAMS"
import { AUTH_EVENTS, HUB_CHANNEL_NAME, authListener, poolName, setIGNORE_EVENTS, signUpOrInUsername } from "./amplify/amplify"
import { saveAmplifyStateOnServer } from './amplify/amplify_state'
import { TRANSLATION } from './amplify/amplify_translations'
import { parseJwt } from './parseJWT'
import { STORAGE, setStorage } from "./server-storage"
import { REDWOOD_ACTIVATION_URL, setNeedsActivationInRedwood } from '../redwood'
import { tokenRefresh } from "../app/shared-worker-on-ui-thread"

let _isSignup = false
/***
 * A signup also results in signin. Unfortunately, the signin is received by the amplify Hub before the Signup.
 * This triggers than already the mapping. But in the end, i need to know whether I need to redirect to redwood or not  
 */
export const setIsSignup = (value: boolean) => {
    _isSignup = value
}

let subscription: Subscription | undefined
export const handleMapping = async (payload: any) => {
    try {  
        Hub.remove(HUB_CHANNEL_NAME, authListener)
        setIGNORE_EVENTS(true)
        setStorage(localStorage)

        let body: any, strBody: string, response: any, result: any
        const error_msg = "Er lijkt iets misgegaan te zijn. Ga terug in je browser en probeer het opnieuw."
        const error_title = "Fout opgetreden"
        const stateStr = STORAGE.getItem(USER_MAPPING_INFO_KEY)
        if (!stateStr) {
            showToast(error_msg, TOAST_LEVELS.ERROR, error_title)
            showSpinner(error_msg, true)
            return
        }

        let state: any
        try {
            state = JSON.parse(stateStr)
        } catch (error) {
            showToast(error_msg, TOAST_LEVELS.ERROR, error_title)
            showSpinner(error_msg, true)
            return
        }

        const pool = findPoolByName(state?.pool) as IPool

        showSpinner(`Koppeling e-ducate.me aan ${state.pool}.`)
        let token = state.token
     
        const onNext$ = new Subject<void>()
        const onNextSubscription =
        onNext$.subscribe(async() => {
            let msg = ""
            switch (payload.event) {
                case AUTH_EVENTS.signIn:
                    msg = `Uw ${state.pool} login wordt nu gekoppeld aan uw e-ducate.me profiel.`
                    showSpinner(msg)
                    break
                case AUTH_EVENTS.confirmSignUp:
                    msg = `Uw ${state.pool} login is nu gekoppeld aan uw nieuw e-ducate.me profiel. 
            Log nu in met je gloednieuwe e-ducate.me profiel.`
                    showSpinner(msg)
                    break
                default:
                    logWarning("unexpected AUTH_EVENTS", payload.event)
            }
   

            if (msg) showToast(msg, TOAST_LEVELS.INFO)
            onNextSubscription?.unsubscribe()

            let destination_url = location.origin
            if (_isSignup) { // new users should be redirected to redwood for action 
                const qs = new URLSearchParams()
                qs.append(QS_REDIRECT_URI, REDWOOD_ACTIVATION_URL())
                destination_url += `?${qs}`
            }
            
            location.href = destination_url
        })

        switch (state.pool) {
            case POOL_NAMES.SMARTSCHOOL:
            case POOL_NAMES.TEST_SMARTSCHOOL:
                const smartschool_id = state.destination_id                
                body = {
                    smartschool_id,
                    username: signUpOrInUsername
                }

                strBody = JSON.stringify(body)
                logDebug("strBody", strBody)

                if (!body.smartschool_id || !body.username) {
                    showToast(error_msg, TOAST_LEVELS.ERROR, error_title)

                    return
                }
                response = await fetch(`${PARAMS.SMARTSCHOOL_LAMBDA}`, {
                    method: "POST",
                    body: strBody
                })
                let session = await response.json()
                logDebug("update smartschool user user_id", session)
                const smartschoolUserAttributes = Object.values(session.cognito_user_attributes)
                showSpinner(`Bezig met het koppelen van de smartschool gebruiker aan de respectievelijke e-ducate.me gebruiker.`)
                
                const username = session?.user?.username || signUpOrInUsername // session?.user?.username || signUpOrInUsername because signUpOrInUsername can be an email (when you fill your email in the loginbox) and then things go wrong
                const { signInUserSession } = await customSignInWithSmartSchool(username, state.token, pool)
                log("signInUserSession", signInUserSession, session)
                STORAGE.setItem("smartschool", JSON.stringify(session)) // ?
                // {"UserAttributes":[{"Name":"sub","Value":"4fd5ff92-a407-4199-aaa9-9a1ebab30cb6"},{"Name":"custom:smartschool_id","Value":"ngA0Vtduyvo="}],"Username":"lkr1"}
                let parsedJWT: IJWT | undefined
                if (signInUserSession) {
                    parsedJWT = parseJwt(signInUserSession.getAccessToken().jwtToken)
                    session = {
                        access_token: signInUserSession.getAccessToken().jwtToken,
                        refresh_token: signInUserSession.getRefreshToken().token,
                        id_token: signInUserSession.getIdToken().jwtToken,
                        user: session.user,
                        issuer: parsedJWT?.iss
                    }
                    log("smartschool session", session);
                } else {
                    const msg = "Kan niet inloggen in cognito/smartschool !"
                    showToast(msg, TOAST_LEVELS.ERROR, TRANSLATION.ERROR_TITLE)
                    showSpinner(msg, true)
                    return 
                }
                setNeedsActivationInRedwood(session)
                await saveAmplifyStateOnServer(findPoolByName(state.pool) as IPool, { "UserAttributes": smartschoolUserAttributes }, signInUserSession, username)
                onNext$.next()
                break
            //@ts-ignore
            case POOL_NAMES.LEER_ID:
            case POOL_NAMES.TEST_LEER_ID: // INTENTIONAL FALLTHROUGH
                body = {
                    leerid_id: state.destination_id,
                    username: signUpOrInUsername,
                    token
                }

                strBody = JSON.stringify(body)
                logDebug("strBody", strBody)

                if (!body.leerid_id || !body.username) {
                    showToast(error_msg, TOAST_LEVELS.ERROR, error_title)
                    showSpinner(error_msg, true)
                    return
                }
                response = await fetch(`${PARAMS.LEERID_LAMBDA}`, {
                    method: "POST",
                    body: strBody
                })
                result = await response.json()
                logDebug("result leerid lambda.", result)
                showSpinner("De leerid gebruiker zijn id wordt aan de corresponderende e-ducateme gebruiker gekoppeld.")
                // refresh the token the get a new idtoken with the user claims in it, after the user has been mapped. The current token is created before teh appingt was established 
                const tokenRefreshResult = await tokenRefresh(pool?.CLIENT_ID, state.session.refresh_token)       
                const leeridSession = {
                    id_token: tokenRefreshResult.id_token, 
                    access_token: tokenRefreshResult.access_token, 
                    refresh_token: state.session.refresh_token         
                }
                await saveAmplifyStateOnServer(findPoolByName(state.pool) as IPool, state.userInfo, leeridSession, state.userInfo.username)
                
                subscription?.unsubscribe()
                onNext$.next()
                break
            case POOL_NAMES.GOOGLE:
            case POOL_NAMES.TEST_GOOGLE:    
                body = {
                    google_id: state.destination_id,
                    username: signUpOrInUsername,
                    token: state.token
                }

                strBody = JSON.stringify(body)
                logDebug("strBody", strBody)

                if (!body.google_id || !body.username) {
                    showToast(error_msg, TOAST_LEVELS.ERROR, "Fout opgetreden")

                    return
                }
                response = await fetch(`${PARAMS.GOOGLE_LAMBDA}`, {
                    method: "POST",
                    body: strBody
                })
                result = await response.json()
                logDebug("update google user user_id", result)
                showSpinner(`De Google gebruiker wordt aan het corresponderende id gekoppeld.`)
                onNext$.next()
                break
            default:
                logError("unhandled POOLNAME in handle mapping", poolName)
                onNext$.next()
                break
        }
        
    } catch (e) {
        logError(e)
    } 
}