<div class="rootContainer">
  <div class="background"></div>
  <div class="content">
    <div class="logoContainer">
      <img [src]="logo" />
    </div>
    <router-outlet>
    </router-outlet>
  </div>
</div>
