import { Component, HostListener, OnInit } from '@angular/core'
import { hideSpinner } from "../spinner"
import { getDefaultPool } from "./PARAMS"
import { logout } from './logout/logout'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @HostListener('window:logoutEvent', ['$event'])
  logo = getDefaultPool().LOGO
  
  constructor() {
    
  }

  async onCustomEvent(event: CustomEvent) {
    await logout()
  }

  ngOnInit() {
    hideSpinner()
  }
}
