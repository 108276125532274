import { IUser } from "redwood-model/dist/IUser"
import { logDebug, logError, logInfo } from "./log"
import { QS_REDIRECT_URI, REDIRECT_URL_ON_STARTUP, qs } from "./main"
import { PARAMS } from "./app/PARAMS"
import { AUTH_EVENTS, getSession } from "./app/amplify/amplify"
import { inIFrame } from "./app/inIFrame"

export const REDWOOD_ACTIVATION_URL = () => `${PARAMS["REDWOOD_UI"]}/school/overview`

export const setNeedsActivationInRedwood = (payloadOrSession: any) => {
    if (payloadOrSession.event ===  AUTH_EVENTS.confirmSignUp || 
        payloadOrSession?.needs_activation ||
        payloadOrSession.user?.needs_activation) { // new users should be redirected to redwood for action 
        logInfo("needs activation handled")
        localStorage[REDIRECT_URL_ON_STARTUP] = REDWOOD_ACTIVATION_URL()
    }
}

export const redirect2RedwoodForActivation = () => {
  if (REDWOOD_ACTIVATION_URL().includes(location.host)) {
    return  
  }

  if (inIFrame()) {
    return
  } 

  location.href = REDWOOD_ACTIVATION_URL()
}

export const doubleCheckNeedsActivation = async (principal: string): Promise<boolean> => {
    try {
        let url: string
        let body = {
          queryType: "DoubleCheckNeedsActivationQuery",
          principal
        }
        
        url = `${PARAMS["REDWOOD-LAMBDA"]}`
        const response = await fetch(url,
        {
          method: "POST",
          body: JSON.stringify(body)
        })
        const data = await response.json()
  
        return data["needs_activation"]
    } catch(e) {
      logError(e)
  
      return Promise.reject(e)
    }
}

export const fetchUserById = async (id: number): Promise<IUser> => {
    try {
        let url: string
        let body = {
          queryType: "UserByIdQuery",
          id,
          includeDisabled: true
        }
  
        url = `${PARAMS["REDWOOD-LAMBDA"]}`
        const response = await fetch(url,
        {
          headers: { "authorization" : `Bearer ${(await getSession()).access_token}`},
          method: "POST",
          body: JSON.stringify(body)
        })
        const data = await response.json()
  
        return data
    } catch(e) {
      logError(e)
  
      return Promise.reject(e)
    }
}
  
export const fetchUserPublications = async (explicitUserId: number): Promise<IUser> => {
    try {
        let url: string
        let body = {
          queryType: "UserPublicationsQuery",
          userId: explicitUserId
        }
  
        url = `${PARAMS["REDWOOD-LAMBDA"]}`
        const response = await fetch(url,
        {
          headers: { "authorization" : `Bearer ${(await getSession()).access_token}`},
          method: "POST",
          body: JSON.stringify(body)
        })
        const data = await response.json()
  
        return data
    } catch(e) {
      logError(e)
  
      return Promise.reject(e)
    }
}

export const verifyEmailCode = async(email: string, code: string) => {
    try {
        let url: string
        let body = {
          email,
          code
        }
  
        url = `${PARAMS.EMAIL_VALIDATOR_LAMBDA}/validate_code`
        const response = await fetch(url,
        {
          headers: { "authorization" : `Bearer ${(await getSession()).access_token}`},
          method: "POST",
          body: JSON.stringify(body)
        })
        const data = await response.json()
  
        return data
    } catch(e) {
      logError(e)
  
      return Promise.reject(e)
    }    
}

export const expireRedwoodSessionCookie = (name: string = "REDWOODSESSID", value: string = ""): void  => {
  const domain = location.hostname.split(".").slice(-2).join(".")
  let cookieString = `${name}=${value};domain=${domain};path=/;Expires=${new Date(0).toUTCString()}`
  document.cookie = cookieString
  logDebug(`Setting cookie by name: ${name}, with value:`, value)
}

export const handleRedwoodSignupRedirect = () => {
  if (qs.has(QS_REDIRECT_URI)) {
    let redirect_uri = qs.get(QS_REDIRECT_URI)?.toLowerCase()
    if (redirect_uri?.endsWith("/")) {
      redirect_uri = redirect_uri.slice(0, -1)
    }
    logDebug(`redirect_uri ${redirect_uri},  ${PARAMS["REDWOOD_UI"]}/register`)
    if (redirect_uri === `${PARAMS["REDWOOD_UI"]}/register`) {
      location.href = `${location.origin}/signup`
    }
  }
}
