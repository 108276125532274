import Toastify from 'toastify-js'

export const TOAST_LEVELS = {
  SUCCES: 'success',
  ERROR: 'error',
  WARN: 'warn',
  INFO: 'info'
}

export function showToast(text: string, level: string, title?: string){
  let style = {
    background: '#56bddc',
    color: 'white'
  };

  switch (level) {
    case 'success':
      style.background = '#4e7e1b';
      break;
    case 'error':
      style.background = '#e63205';
      break;
    case 'warn':
      style.background = '#ff8000';
      break;
    case 'info':
    default:
      style.background = '#238aa9';
      break;
  }
    Toastify({
      text: title ? `<b>${title}</b><br>${text}` : text,
      duration: 3000,
      close: false,
      gravity: "top", // `top` or `bottom`
      position: "right", // `left`, `center` or `right`
      stopOnFocus: true, // Prevents dismissing of toast on hover
      escapeMarkup: false,
      style,
      onClick: function(){} // Callback after click
    }).showToast();
  }