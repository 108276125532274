import { IJWT } from "../IJWT"
import { IPool, PARAMS } from "../app/PARAMS"
import { STORAGE } from "../app/server-storage"
import { logDebug, logError } from "../log"
import { USER_MAPPING_INFO_KEY } from "../main"
import { IOnTokenResult } from "./common"

const getUserFromGoogleMap = async (user_id: string, token: string) => {
    try {
      const urlSearchParams = new URLSearchParams()
      urlSearchParams.append("google_id", user_id)
      urlSearchParams.append("token", token)
      logDebug("getUserFromGoogleMap", urlSearchParams)
      const response = await fetch(`${PARAMS.GOOGLE_LAMBDA}?${urlSearchParams}`)
  
      const data = await response.json()
      if (data && JSON.stringify(data) === "{}") {
        return undefined
      }
  
      return data
    } catch (error) {
      logError("getUserFromGoogleMap Error:", error)
      throw error
    }
}
  
export const onGoogleToken = async(mappedUser: any, pool: IPool, parsedJWT: IJWT | undefined, session: any): Promise<IOnTokenResult> => {
    mappedUser = await getUserFromGoogleMap(parsedJWT?.sub as string, session?.access_token)
    if (!mappedUser) {
      const userInfo = { username: parsedJWT?.username as string }
      STORAGE.setItem(USER_MAPPING_INFO_KEY, JSON.stringify({
        "destination_id": parsedJWT?.sub,
        "pool": pool.NAME,
        "token": session?.access_token
      }))
      location.href = `${location.origin}/map`
  
      return {
        exit: true,
        mappedUser,
        needsActivation: mappedUser?.needs_activation
      }
    }
  
    return { exit: false, mappedUser, needsActivation: false }
  }