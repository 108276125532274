import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AmplifyLoginComponent } from './amplify-login/amplify-login.component'
import { ChangePswComponent } from './change-psw/change-psw.component'
import { LoginComponent } from './login/login.component'
import { LogoutComponent } from './logout/logout.component'
import { MfaComponent } from './mfa/mfa.component'
import { ProfileComponent } from './profile/profile.component'
import {AltResetPswComponent} from "./alt-reset-psw/alt-reset-psw.component";

const routes: Routes = [
  { path: 'profile', component: ProfileComponent },
  { path: 'profile/:userId', component: ProfileComponent },
  { path: 'profile/:userId/edit', component: ProfileComponent, data: { isEditMode: true } },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: LoginComponent },
  { path: 'reset-pw', component: AltResetPswComponent },
  { path: 'amplify-login', component: AmplifyLoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'mfa', component: MfaComponent },
  { path: 'change-psw', component: ChangePswComponent },
  { path: 'map', component: AmplifyLoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
