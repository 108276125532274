import { Component } from '@angular/core'
import { logError } from '../../log'
import { PARAMS } from '../PARAMS'
import { getSession } from '../amplify/amplify'
import { Subject } from 'rxjs'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { verifyEmailCode } from '../../redwood'



@Component({
  selector: 'app-email-validator-code',
  templateUrl: './email-validator-code.component.html',
  styleUrls: ['./email-validator-code.component.scss']
})
export class EmailValidatorCodeComponent {
  emailVerifyForm: FormGroup | undefined
  code = ""
  email = ""
  OnCodeVerified$ = new Subject()

  constructor(public bsModalRef: BsModalRef, 
              private formbuilder: FormBuilder) {

  }

  ngOnInit() {
    this.emailVerifyForm = this.formbuilder.group({
      code: ['', Validators.required],
    });
  }

  async verify() {
    const { code } = this.emailVerifyForm?.value
    const result = await verifyEmailCode(this.email, code)
    this.OnCodeVerified$.next(result)
    this.bsModalRef.hide()
  }
}
