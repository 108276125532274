import { CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUserPool, CognitoUserSession, ICognitoUserPoolData } from "amazon-cognito-identity-js"
import { logDebug, logError } from "../log"
import { PARAMS } from "./PARAMS"
import { getClaimsFromIdToken, parseJwt } from "./parseJWT"
import { IUser } from "redwood-model/dist"
import { REDWOOD_USER } from "./shared-worker-on-ui-thread"
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import { ISession } from "./ISession"
import { doubleCheckNeedsActivation, redirect2RedwoodForActivation } from "src/redwood"

class KnownEmailStore {
  KNOWN_EMAILS_KEY = "KNOWN_EMAILS_KEY"

  get storedEmails(): Set<string> {
    const current = localStorage["KNOWN_EMAILS_KEY"]
    if (!current) return new Set()
    return new Set(JSON.parse(current))
  }

  addEmail(email: string) {
    const emails = this.storedEmails;
    emails.add(email.toLowerCase())

    localStorage["KNOWN_EMAILS_KEY"] = JSON.stringify(Array.from(emails))
  }

  hasEmail(email: string) : boolean {
    return this.storedEmails.has(email.toLowerCase())
  }
}

const knownEmailStore = new KnownEmailStore()

export const isUserKnownToCognito = async (email: string): Promise<boolean> => {
    try {
      if (knownEmailStore.hasEmail(email)) {
        return true
      }
      const urlSearchParams = new URLSearchParams()
      urlSearchParams.append("email", email)
      logDebug("isUserKnownToCognito", email)
      const response = await fetch(`${PARAMS.EDUCATEME_LAMBDA}?${urlSearchParams}`)
  
      const data = await response.json()
        
      if(data?.registered) {
        knownEmailStore.addEmail(email)
      }
      return data?.registered
    } catch (error) {
      logError("isUserKnownToCognito", email)
      throw error
    }
}

export const policyCache = 'policy-cache'
export const fetchPswPolicy = async (): Promise<any> => {
  let url = PARAMS["PSW_POLICY_LAMBDA"]
  const cache = await caches.open(policyCache);
  const cachedResponse = await cache.match(url);
  if (cachedResponse) return cachedResponse.json();

  try {
      const response = await fetch(url)
      const data = await response.json()
      await cache.put(url, new Response(JSON.stringify(data)));

      return data
  } catch(e) {
    logError(e)

    return Promise.reject(e)
  }
}

export const cognitoSession2User = async (sessionOrIdToken: CognitoUserSession | ISession | string): Promise<any> => {
  const anySession = sessionOrIdToken as any
  let idToken = ""
  if (typeof sessionOrIdToken === "string")  {
    idToken = sessionOrIdToken as string
  } else {
    idToken = anySession["id_token"] || anySession.getIdToken()?.getJwtToken()
  }
  if (!idToken) {
    return Promise.reject("Missing idToken for cognitoSession2User")
  }
  const claims = await getClaimsFromIdToken(idToken)
  let publications = []
  if (claims?.redwood_pub_ids) {
    const pub_ids: string[] = JSON.parse(claims.redwood_pub_ids)
    for (const id in pub_ids) {
      publications.push({id})
    }
  }
  logDebug(`access token from authorization header`)
  logDebug("claims", JSON.stringify(claims))
  const first_name = claims.redwood_first_name || claims.given_name || ""
  const last_name = claims.redwood_last_name || claims.family_name || ""
  const email = claims.redwood_email || claims.email || ""
  const currentUser = {
    username: claims['cognito:username'],
    enabled: true,
    email,
    id: claims.redwood_user_id ? Number.parseInt(claims.redwood_user_id) : 0,
    first_name,
    last_name,
    full_name: `${first_name} ${last_name}`,
    roles: JSON.parse(claims.redwood_roles),
    uid: claims.redwood_uid,
    avatar: claims["redwood_avatar"],
    publications,
    last_updated: new Date().getTime(),
    needs_activation: claims.redwood_needs_activation === "true"
  }     
  
  REDWOOD_USER.set(currentUser)

  return currentUser
}

export const signinByTokens = async (IdToken: string, AccessToken: string, RefreshToken: string): Promise<CognitoUserSession> =>  {
  const parsedJWT = parseJwt(AccessToken)
  const ClientId = parsedJWT?.client_id
  if (!ClientId) {
    const err_msg = "no Clientid found in access_token"
    logError(err_msg)
    return Promise.reject(err_msg) 
  }

  const iss = parsedJWT.iss
  const UserPoolId = iss.substring("https://cognito-idp.eu-central-1.amazonaws.com/".length)
  const claims = await getClaimsFromIdToken(IdToken)
  const cognitoUserPoolData: ICognitoUserPoolData = {
		UserPoolId,
		ClientId
	}

  // Create a Cognito user object
  const cognitoUser = new CognitoUser({
    Username: claims["username"] || claims["cognito:username"],
    Pool: new CognitoUserPool(cognitoUserPoolData)
  })

  // Define the session object with your tokens
  const sessionData = {
    IdToken: new CognitoIdToken({ IdToken }),
    AccessToken: new CognitoAccessToken({ AccessToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken }), // Optional, but can be included if available
  }

  // Set the session manually
  cognitoUser.setSignInUserSession(new CognitoUserSession(sessionData))

  return new Promise(async (resolve, reject) => {
    // To confirm login, you may call getSession or any authenticated API
    cognitoUser.getSession(async (err: Error, session: any) => {
      if (err) {
        logError('Error setting session:', err)
        reject(err)
      } else {
        logDebug('Session set successfully:', session)
        await cognitoSession2User(session)
        resolve(session)
      }
    })
  })

}


