import { ReCaptchaV3Service } from "ng-recaptcha"
import { Subscription } from "rxjs"

let CaptchaV3Service : ReCaptchaV3Service | undefined

export const executeCaptchaAction = async (action: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const $obs = CaptchaV3Service?.execute(action)
    const s: Subscription | undefined =
    $obs?.subscribe((token) => {
      setTimeout(() => {
        if (s) { s.unsubscribe() }
      }, 0)
      if (token) {
        resolve(token)
      }
      else reject('no token from re-captcha')
    })
  })
}

export const setCaptchaV3Service = (c: ReCaptchaV3Service) => {
    CaptchaV3Service = c   
}