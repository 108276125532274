import { CommonModule } from '@angular/common'
import { LOCALE_ID, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular'
import { AutocompleteLibModule } from 'angular-ng-autocomplete'
import { RECAPTCHA_LANGUAGE, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha"
import { PARAMS } from './PARAMS'
import { AmplifyLoginComponent } from './amplify-login/amplify-login.component'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ChangePswComponent } from './change-psw/change-psw.component'
import { LoginComponent } from './login/login.component'
import { LogoutComponent } from './logout/logout.component'
import { MfaComponent } from './mfa/mfa.component'
import { ProfileComponent } from './profile/profile.component'
import { ModalModule } from 'ngx-bootstrap/modal'
import { EmailValidatorCodeComponent } from './email-validator-code/email-validator-code.component'
import {AltResetPswComponent} from "./alt-reset-psw/alt-reset-psw.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    MfaComponent,
    AmplifyLoginComponent,
    ChangePswComponent,
    ProfileComponent,
    EmailValidatorCodeComponent,
    AltResetPswComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    AmplifyAuthenticatorModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    RecaptchaV3Module,
    ModalModule.forRoot(),
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: PARAMS.RECAPTCHA },
    { provide: LOCALE_ID, useValue: 'nl-BE' },
    {
      provide: RECAPTCHA_LANGUAGE,
      useFactory: (locale: string) => locale,
      deps: [LOCALE_ID],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
