/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { IJWT } from './IJWT'
import { EVENTS } from './app/EVENTS'
import { PARAMS, POOL_NAMES, findPoolByClientId, findPoolById, initParams } from "./app/PARAMS"
import { getCurrentAmplifyUser, getSession, initAmplify } from './app/amplify/amplify'
import { getClientId, getPoolId, LAST_AUTH_USER, saveAmplifyStateOnServer, setPoolId, verifyCleanupAmplifyState } from './app/amplify/amplify_state'
import { AppModule } from './app/app.module'
import { AllowedParentOrigin, inIFrame } from './app/inIFrame'
import { extractPOOL_IDFromJWT, parseJwt } from './app/parseJWT'
import { CHANNEL, REDWOOD_USER, SHARED_WORKER, initSharedWorker, postMsg2ChannelOrWorker, tokenRefresh } from './app/shared-worker-on-ui-thread'
import { exchangeCodeForToken } from './exchangeToken4Code'
import { log, logDebug, logError, logWarning } from './log'
import { IOnTokenResult } from './pools/common'
import { onGoogleToken } from './pools/google'
import { onLeerIDToken } from './pools/leerid'
import { onSmartSchoolToken } from './pools/smartschool'
import { REDWOOD_ACTIVATION_URL, doubleCheckNeedsActivation, handleRedwoodSignupRedirect, redirect2RedwoodForActivation } from './redwood'
import { showSpinner } from './spinner'
import { getTokenRefreshInterval, handleRedirectURI, handleThirdPartyLogout, isAndroid, isSafariOnIPadOrMac, reInitAmplify, storeToCookie, withTimeout } from './utils'
import { STORAGE } from './app/server-storage'
import { safeGuardInfiniteReload } from './app/safeGuardInfiniteReload'

showSpinner('SSO wordt opge- of herstart.')
initParams()

export const isMAPPING_USER = () => { return location.pathname === "/map" }
export const USER_MAPPING_INFO_KEY = "userMappingInfo"
export const QS_REDIRECT_URI = "redirect_uri"
export const QS_RE_INIT = "reinit"
export const QS_CODE = "code"
export const REDIRECT_URL_ON_STARTUP = "redirect_on_startup"
export const qs = new URLSearchParams(location.search)
const QS_SESSION_ID = "sso_session_id"

export const onUnhandledError = (e?: Error) => {
  if (!e) e = new Error("Onbekende fout")
  STORAGE.clear()
  showSpinner(`Er is een fout opgetreden : ${e}, U wordt nu  automatisch uitgelogd.`, true)
  setTimeout(() => {
    location.reload()
  }, 3500)
}

const startUi = async () => {
    if (inIFrame()) return

    return platformBrowserDynamic().bootstrapModule(AppModule).catch(e => {
      logError(e)
      showSpinner(`Er is een fout opgetreden : ${e}.`, true)
      }
    )
}

(async() => {
  // SSO entry point
  safeGuardInfiniteReload()
  handleThirdPartyLogout()
  verifyCleanupAmplifyState()
  handleRedwoodSignupRedirect()
  if (qs.has(QS_CODE)) {
    try {
      const lastUserPoolId = getPoolId()
      if (lastUserPoolId) {
        const lastPool = findPoolById(lastUserPoolId)
        if (lastPool) {
          showSpinner('Ophalen e-ducate.me gebruiker op basis van authorisatie code, momentje ...')
          let session = await exchangeCodeForToken(qs.get(QS_CODE) as string)
          let parsedJWT: IJWT | undefined = parseJwt(session?.access_token)
          if (parsedJWT) {
            log("parsedJWT", JSON.stringify(parsedJWT))
          }
          let mappedUser: any
          let iOnTokenResult: IOnTokenResult | undefined
          let redirect_uri = localStorage[REDIRECT_URL_ON_STARTUP]
          switch (lastPool.NAME) {
            case POOL_NAMES.TEST_SMARTSCHOOL: // INTENTIONAL FALLTRHOUGH
            case POOL_NAMES.SMARTSCHOOL: {
              iOnTokenResult = await onSmartSchoolToken(session, lastPool)
              if (iOnTokenResult.exit) return
              session = iOnTokenResult.session
              mappedUser = iOnTokenResult.mappedUser
              break
            }
            case POOL_NAMES.LEER_ID:
            //@ts-ignore
            case POOL_NAMES.TEST_LEER_ID: // INTENTIONAL FALLTHROUGH
              iOnTokenResult = await onLeerIDToken(session, lastPool, parsedJWT, mappedUser)
              mappedUser = iOnTokenResult?.mappedUser
              if (mappedUser) { await initAmplify() } 
              await startUi()
              if (!mappedUser) return
              break
            case POOL_NAMES.GOOGLE:
              //@ts-ignore
            case POOL_NAMES.TEST_GOOGLE: // INTENTIONAL FALLTHROUGH
              iOnTokenResult = await onGoogleToken(mappedUser, lastPool, parsedJWT, session)
              if (iOnTokenResult.exit) return
              mappedUser = iOnTokenResult.mappedUser
              await saveAmplifyStateOnServer(lastPool, mappedUser, session, mappedUser?.username)
              break
            default:
              logWarning(`Pool name unhandled ?!`, lastPool.NAME)
              break
          }

          log("init sharedWorker")
          log("mappedUser?.username", mappedUser?.username)
          const workerPromise = initSharedWorker()
          await postMsg2ChannelOrWorker({
              event: EVENTS.startTimer,
              username: mappedUser?.username || parsedJWT?.username || session?.user?.username,
              userPoolId: getPoolId(),
              clientId: getClientId(),
              refresh_token: session.refresh_token || session.refreshToken.token,
              access_token: session.access_token || session.accessToken.jwtToken, 
              id_token: session.id_token || session.idToken.jwtToken,
              issuer: parsedJWT?.iss,
              jsonUser: session.user ? JSON.stringify(session.user) : undefined,
              refresh_interval: getTokenRefreshInterval(),
              location: location.origin,
              userAttributes: session?.user,
              oauth_lambda_url : PARAMS.OAUTH_LAMBDA
          }, session.user)
          //await workerPromise

          if (iOnTokenResult?.needsActivation) { // keep redirecting to redwood until activated !
            redirect_uri = REDWOOD_ACTIVATION_URL()
          }
          reInitAmplify(redirect_uri)
        }
      }
    } catch(e) {
      showSpinner(`Er is een fout opgetreden : ${e}.`, true)
    }
  } else {
    try {
      let lastUserPoolId = getPoolId()
      const findLastAuthUserKey = () => {
        for (let i = 0; i < Object.keys(STORAGE).length; i++) {
            const key = Object.keys(STORAGE)[i] as string
            if (key.endsWith(LAST_AUTH_USER)) {
                return key
            }
        }
        return undefined
      }
      // check POOL_ID in localstorage is not overwritten
      const lastAuthUserKey = findLastAuthUserKey()
      let clientId = ""
      if (lastAuthUserKey) {
        clientId = lastAuthUserKey.split(".")[1]
        if (clientId) {
          const lastPool = findPoolByClientId(clientId)
          if (lastPool) {
            lastUserPoolId = lastPool.USER_POOL_ID
          }
        }
      }

      await withTimeout(async () => { // in case the user state goes corrupt (especially UserAttributes) amplify  doesnt always throw a proper exception and the app doesn't continue, it just shows en error on console
        if (lastUserPoolId) {
          await initAmplify(lastUserPoolId)
        }
        if (!await getCurrentAmplifyUser()) {
          await initAmplify()    
        }
      }, 1500)
      
      let workerPromise = initSharedWorker()
      let session = await getSession()
      if (session) {
        const access_token = session.access_token as string
        let parsedJWT: IJWT | undefined = parseJwt(access_token)
        if (!(session?.user_attributes) || Object.keys(session.user_attributes).length === 0) {
          const key = `CognitoIdentityServiceProvider.${clientId}.${session?.username}.userData`
          if(STORAGE[key]) {
            try  {
              session.user_attributes = JSON.parse(STORAGE[key])
            } catch(e) {
              logError("error while trying to recover attributes from localstotage", e)
            }
          }
        }
        const postMsg = {
          event: EVENTS.startTimer,
          username: session?.username,
          userPoolId: getPoolId(),
          clientId: getClientId(),
          refresh_token: session?.refresh_token,
          access_token: access_token,
          id_token: session?.id_token,
          refresh_interval: getTokenRefreshInterval(),
          issuer: parsedJWT?.iss,
          location: location.origin,
          userAttributes: session.user_attributes,
          oauth_lambda_url : PARAMS.OAUTH_LAMBDA
        }
        if (parsedJWT) {
          // Get the current time (in seconds)
          const currentTime = Math.floor(Date.now() / 1000)
          if (currentTime > parsedJWT.exp) { // smartschool doesn't auto refresh the token on get session
            const result = await tokenRefresh(getClientId() as string, session?.refresh_token as string)
            session.id_token = postMsg.id_token = result.id_token
            session.access_token = postMsg.access_token = result.access_token
          }
          if (lastUserPoolId) {
            const pool = findPoolById(lastUserPoolId)
            if (pool) {
              await saveAmplifyStateOnServer(pool, session.user_attributes, session, session?.username as string)
            }
          }
        }
        setPoolId(extractPOOL_IDFromJWT(parsedJWT as IJWT))
        if (location.pathname !== "/logout") {
          await postMsg2ChannelOrWorker(postMsg, session.user)
          //await workerPromise
          const ru: any = REDWOOD_USER()
          if (localStorage[REDIRECT_URL_ON_STARTUP] && ru && !ru.needs_activation) {
            localStorage.removeItem(REDIRECT_URL_ON_STARTUP)
          }
          if (ru?.needs_activation) { // keep redirecting to redwood until activated !
            const still_needs_activation = await doubleCheckNeedsActivation(ru.username) // by-pass cache
            if (still_needs_activation) redirect2RedwoodForActivation()
          }
          handleRedirectURI()
        }
        await startUi()
      } else {
        throw new Error("Auth.currentAuthenticatedUser fails")
      }
    } catch(e) {
      try {
        if (e?.toString() === "The user is not authenticated") { 
          await startUi()
          return 
        } else {
          onUnhandledError()
        }
      } catch(e) {
        onUnhandledError()
      } finally {
        const msg = { access_token : undefined, id_token : undefined, user: undefined, event: EVENTS.tokenChange }
        logDebug("parent.postMessage", msg)
        if (isSafariOnIPadOrMac() || isAndroid()) { // safari receives an empty msg on postMessage, so we pass the msg by a cookie ...
            storeToCookie(msg)
        }
        CHANNEL.postMessage(
          JSON.stringify(msg),
          AllowedParentOrigin()
        )
      }
    }
  }
})()





