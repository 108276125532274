import { generateUUID } from "src/utils"

export const RELOAD_TIMES = 'reloadTimestamps'
const MAX_RELOADS = 5
const RELOAD_PERIOD = 10 * 1000 // 10 seconds

export const safeGuardInfiniteReload = (instanceId?: string) => {

  // avoid this mechanism kicks in when quickly switching between redwood and sso profile
  const regex = /^https:\/\/sso\.[^\/]+\/profile\/.+/
  if (regex.test(location.href)) {
    return 
  } 

  const storage = sessionStorage
  
  // If no instanceId is provided, generate one and store it in sessionStorage
  if (!instanceId) {
    instanceId = storage.getItem('ssoInstanceId') || generateUUID()
    storage.setItem('ssoInstanceId', instanceId)
  }

  const currentTimestamp = Date.now()
  
  // Get the reload timestamps for this specific instance
  const strReloadTimestamps = storage.getItem(`${RELOAD_TIMES}_${instanceId}`)
  let reloadTimestamps = strReloadTimestamps ? JSON.parse(strReloadTimestamps) : []
  reloadTimestamps.push(currentTimestamp)

  // Filter out timestamps older than RELOAD_PERIOD
  const relevantPeriodAgo = currentTimestamp - RELOAD_PERIOD
  reloadTimestamps = reloadTimestamps.filter((timestamp: number) => timestamp > relevantPeriodAgo)

  // Limit the array to the last 25 timestamps
  if (reloadTimestamps.length > 25) {
    reloadTimestamps = reloadTimestamps.slice(-25)
  }

  // Store the updated reload timestamps for this instance
  storage.setItem(`${RELOAD_TIMES}_${instanceId}`, JSON.stringify(reloadTimestamps))

  // Check if there are more than MAX_RELOADS reloads in the last RELOAD_PERIOD
  if (reloadTimestamps.length > MAX_RELOADS) {
    storage.removeItem(`${RELOAD_TIMES}_${instanceId}`)
    storage.removeItem('ssoInstanceId')
    //alert('SSO is teveel herladen, u wordt nu automatisch uitgelogd.')
    // Optionally add logout logic or other cleanup here
  }

  console.log(`SSO instance ${instanceId} reload timestamps: ${reloadTimestamps.map((ts: number) => new Date(ts).toLocaleString()).join(', ')}`)
}
