<div class="centered-container">
    <div>
      <app-amplify-login (userIsAuthenticated)="setUser($event)"></app-amplify-login>
      <div class="helpContainer" *ngIf="!user">
        <a href="https://diligentia.zendesk.com/hc/nl/requests/new" target="_blank" >Hulp nodig?</a>
      </div>
      <ul class="poolList" *ngIf="!user">
        <li class="poolListItem" *ngFor="let pool of pools">
          <button class="centered-button amplify-button--primary secondary" (click)="openUrl(pool)" >
            <img [src]="pool.LOGO" alt="{{ pool.NAME }}" />
            <span>Aanmelden met {{pool.DISPLAY_NAME}}</span>
          </button>
        </li>
      </ul>

    </div>
</div>



