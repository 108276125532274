<div class="container">
  <ng-container *ngIf="componentState === 'CHECK_USERNAME'">
    <form [formGroup]="checkUserNameForm" (ngSubmit)="sendCodeHandler($event)">
      <h3 class="amplify-heading amplify-heading--3">Wachtwoord resetten</h3>
      <label class="amplify-label" for="userName">Vul je email of gebruikersnaam in</label>
      <input class="amplify-input" id="userName" type="text" formControlName="userName">
      <input class="thepot" type="text" formControlName="thepot" value=""/>
      <ng-container *ngIf="errorMessage.length">
        <p class="errors">{{errorMessage}}</p>
      </ng-container>
      <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" type="submit" >Verstuur code</button>
      <button class="amplify-button amplify-button--fullwidth amplify-button--link amplify-button--small" (click)="cancel()"> terug naar inloggen</button>
    </form>
  </ng-container>
  <ng-container *ngIf="componentState === 'RESET_PASSWORD'">
    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPasswordHandler($event)" autocomplete="off">
      <h3 class="amplify-heading amplify-heading--3">Wachtwoord resetten</h3>
      <input id="user-name" style="width: 0; height: 0; position: fixed; left: -9000px" type="text">
      <input id="password" style="width: 0; height: 0; position: fixed; left: -9000px" type="password">
      <label class="amplify-label" for="codeField">Code</label>
      <input class="amplify-input" placeholder="code" id="codeField" autocomplete="off" type="text" formControlName="codeField">
      <label class="amplify-label" for="newPasswordField">Nieuw wachtwoord</label>
      <input class="amplify-input" placeholder="nieuw wachtwoord" id="newPasswordField" autocomplete="off" type="password" formControlName="newPasswordField" (input)="checkPasswordPolicy($event)">
      <label class="amplify-label" for="confirmPasswordField">Bevestig wachtwoord</label>
      <input class="amplify-input" placeholder="bevestig wachtwoord" id="confirmPasswordField" autocomplete="off" type="password" formControlName="confirmPasswordField" (input)="checkConfirm($event)">
      <input class="thepot" type="text" formControlName="thepot" value=""/>
      <ng-container *ngIf="errorMessage.length">
        <p class="errors">{{errorMessage}}</p>
      </ng-container>
      <button [disabled]="errorMessage.length" class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" type="submit" >Reset wachtwoord</button>
      <button class="amplify-button amplify-button--fullwidth amplify-button--link amplify-button--small" (click)="cancel()"> terug naar inloggen</button>
    </form>
  </ng-container>
</div>


