
<div class="centered-container amplifyBorders">
    <div>
      <div class="imageContainer">
        <img src="assets/logout-svgrepo-com.svg"/>
      </div>
        <h3>Afmelden</h3>
        <p>Uw sessie werd beëindigd.</p>
    </div>
</div>
