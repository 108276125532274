<div class="container">
  <div class="mapUser" *ngIf="isMapUser">
    <h1>Koppel nu je <i><u>e-ducate.me</u></i> profiel aan je <i><u>{{destination_pool}}</u></i> account.</h1>
    <h2>Heb je al een profiel van e-ducate.me ?</h2>
    <p>Vul dan hieronder je gebruikersnaam of je e-mailadres in met het wachtwoord dat je gebruikt voor e-ducate.me.
    <h3>
      <em>Opgelet: vul hier alsjeblieft <b>NIET</b> je {{destination_pool}} login in, maar je <b>e-ducate.me</b> login !</em>
    </h3>
    <h2>Heb je nog geen e-ducate.me profiel ?</h2>
    <p>Geen probleem, klik dan snel op Account aanmaken.</p>
    <hr>
  </div>
</div>
<div #amplifyContainer >
  <amplify-authenticator *ngIf="showAmplifyAuthenticator" [signUpAttributes]="[
  'email',
  'family_name',
  'given_name',
  'nickname',
  'updated_at'
  ]"
  [services]="services"
  >
    <ng-template
      amplifySlot="authenticated"
      let-user="REDWOOD_USER()"
    >
      <div class="amplifyBorders">
        <h3 [hidden]="!userDisplayName">Welkom {{userDisplayName}} !</h3>
        <p class="loginInfo"> U bent aangemeld via {{destination_pool}}</p>
        <ul *ngIf="!isMapUser" style="list-style: none;">
          <li [hidden]="!userDisplayName">
            <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" style="font-weight: normal;" (click)="profile()">Profiel</button>
          </li>
          <li>
            <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" style="font-weight: normal;" (click)="mail()">E-mails</button>
          </li>
          <li>
            <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" style="font-weight: normal;" (click)="signOut()">Uitloggen</button>
          </li>
          <li *ngIf="destination_pool === E_DUCATE_ME || destination_pool === TEST_E_DUCATE_ME">
            <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" style="font-weight: normal;"(click)="changePSW()">Wijzig Wachtwoord</button>
          </li>
          <li *ngIf="destination_pool === E_DUCATE_ME || destination_pool === TEST_E_DUCATE_ME">
            <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" style="font-weight: normal;" (click)="mfa()">Beheer MFA</button>
          </li>
          <!--
          <li *ngIf="!isProd">
            <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" style="font-weight: normal;" (click)="organizations()">Organizaties</button>
          </li>
          <li *ngIf="!isProd">
            <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" style="font-weight: normal;" (click)="roles()">Beheer rollen</button>
          </li>-->
        </ul>
      </div>

    </ng-template>
    <ng-template
      amplifySlot="sign-up-form-fields"
    let-validationErrors="validationErrors"
    >
    <!-- Re-use default `Authenticator.SignUp.FormFields` -->
    <amplify-sign-up-form-fields></amplify-sign-up-form-fields>
    <input type="hidden" name="custom:captchaHidden" id="captchaHidden" >
    <input type="hidden" name="custom:captchaRest" id="captchaRest" >
    <input class="thepot" type="text" name="custom:honeyPot" value=""/>
</ng-template>

</amplify-authenticator>


