import { log, logDebug, logError } from "../../log"
import { TOAST_LEVELS, showToast } from "../../toast"
import { IPool, findPoolByClientId, findPoolById, isProd } from "../PARAMS"
import { TRANSLATION } from "./amplify_translations"
import { STORAGE } from "../server-storage"

export const POOL_ID_KEY = "POOL_ID"

export const setPoolId = (value: string) => {
    STORAGE.setItem(POOL_ID_KEY, value)
}

export const getPoolId = (): string | null => {
    try {
        return STORAGE.getItem(POOL_ID_KEY)
    } catch(e) {
        logError("getPoolId caught", e)
        
        return null
    }
}

export const getClientId = (): string | undefined => {
    const poolId = STORAGE.getItem(POOL_ID_KEY)
    if (poolId) {
        const pool = findPoolById(poolId)
        return pool?.CLIENT_ID
    }

    return undefined
}

export const getPool = (): IPool | undefined => {
    return findPoolByClientId(getClientId() as string)
}

export const LAST_AUTH_USER = "LastAuthUser"

export const serializeAmplifyState = (pool: IPool, userAttributes: any, session: any, username: string) => {
    const { CLIENT_ID, USER_POOL_ID } = pool
    
    let { id_token, access_token, refresh_token } = session
    if (session.constructor.name === 'CognitoUserSession') {
      access_token = session.accessToken.jwtToken
      id_token = session.idToken.jwtToken
      refresh_token = session.refreshToken.token
    }

    const toValidate = [
        { name: "gebruikersnaam", value: username },
        { name: "gebruikers attributen", value: userAttributes },
        { name: "id token", value: id_token},
        { name: "access token", value: access_token},
        { name: "refresh token", value: refresh_token},
    ]
    
    const missingInputs: string[] = []
    toValidate.forEach(tv => {
        if (tv.value) return 
        missingInputs.push(tv.name)
    })

    if (missingInputs.length) {
        const msg = `${missingInputs.join(",")} onbreekt tijdens bewaren sessie. <BR/>`
        logError(msg, pool, userAttributes, session, username)
        if (!isProd()) {
          showToast(msg, TOAST_LEVELS.WARN, TRANSLATION.WARN_TITLE)
        }
        return
    }

    const prefix = `CognitoIdentityServiceProvider.${CLIENT_ID}`
    const userPrefix = `${prefix}.${username}`

    // there can only be one LastAuthUser key
    ensureNoLastAuthUserKey(STORAGE)
    
    STORAGE[POOL_ID_KEY] = USER_POOL_ID
    STORAGE[`${userPrefix}.idToken`] = id_token
    STORAGE[`${userPrefix}.accessToken`] = access_token
    STORAGE[`${userPrefix}.refreshToken`] =  refresh_token
    if(typeof session?.clockDrift === "number") {
        STORAGE[`${userPrefix}.clockDrift`] = session?.clockDrift
    } else {
        STORAGE[`${userPrefix}.clockDrift`] = 0
    }
    STORAGE[`${prefix}.${LAST_AUTH_USER}`] = username
  
    if (!Object.keys(userAttributes).includes("UserAttributes")) {
        userAttributes = {
            "UserAttributes": userAttributes
        }
    }
    STORAGE[`${userPrefix}.userData`] = JSON.stringify(userAttributes)
    
    return STORAGE
}

export const saveAmplifyStateOnServer = async (pool: IPool, userAttributes: any, session: any, username: string) => {
    await serializeAmplifyState(pool, userAttributes, session, username)
}

export const tryGetTokenFromAmplifyState = (stateObj: any, token_postfix = ".accessToken"): string | undefined => {
    try {
      const returnValue = hasSingleCognitoIdentityKey(stateObj)
      if (returnValue.result) return stateObj[returnValue.key + token_postfix]
      for (const key in stateObj) {
        if (key.endsWith(LAST_AUTH_USER)) {
          const newKey = key.replace(LAST_AUTH_USER, `${stateObj[key]}${token_postfix}`)
          
          return stateObj[newKey]
        }
      }
  
      return undefined
    } catch (error) {
      logError("Error parsing JSON:", error)

      return undefined
    }
}

// check no LastUser, not logged in thus
const hasSingleCognitoIdentityKey = (data: Record<string, string>): { result: boolean, key: string } => {
    const prefix = 'CognitoIdentityServiceProvider.'
    const identifierSet = new Set<string>()
    let identifier  = ""
    for (const key in data) {
      if (key.startsWith(prefix)) {
        const restOfKey = key.slice(prefix.length)
        const parts = restOfKey.split('.');
        identifier = `${parts[0]}.${parts[1]}`
        identifierSet.add(identifier)
      }
    }
  
    return { result: identifierSet.size === 1, key: prefix + identifier }
}

export const ensureNoLastAuthUserKey = (state: any) => {
    for (let i = 0; i < Object.keys(state).length; i++) {
        const key = Object.keys(state)[i] as string
        if (key.endsWith(LAST_AUTH_USER)) {
            delete state[key]
            logDebug("delete key ", key)
        }
    }
}

export const verifyCleanupAmplifyState = () => {
    const localStorageKeys = Object.keys(localStorage);
    const keysToRemove = new Set<string>();
    const processedCombinedPrefixes = new Set<string>();
    const requiredProperties = ['idToken', 'clockDrift', 'refreshToken', 'accessToken', 'userData'];
    
    localStorageKeys.forEach(key => {
        if (key.startsWith('CognitoIdentityServiceProvider.')) {
            const keyParts = key.split('.');
      
            // Check if it's a key related to a user
            if (keyParts.length >= 4) {
              const username = keyParts.slice(2, -1).join('.')
              const cognPlusClientId = keyParts.slice(0, 2).join('.')
              const combinedPrefix = cognPlusClientId +  '.' + username + '.'
      
              // Only process unique combined prefixes
              if (!processedCombinedPrefixes.has(combinedPrefix)) {
                processedCombinedPrefixes.add(combinedPrefix)
                let hasAllRequiredProperties = true;
      
                // Check if all required properties are present
                requiredProperties.forEach(suffix => {
                  const fullKey = combinedPrefix + suffix;
      
                  if (!localStorage.getItem(fullKey)) {
                    hasAllRequiredProperties = false;
                  }
                });
      
                // If not all required properties are present, add keys to remove set
                if (!hasAllRequiredProperties) {
                  localStorageKeys
                    .filter(k => k.startsWith(combinedPrefix))
                    .forEach(k => keysToRemove.add(k));
      
                  // Also, add the LastAuthUser key if it points to a username that doesn't have all required properties
                  const lastAuthUserKey = cognPlusClientId + '.LastAuthUser';
                  const lastAuthUserValue = localStorage.getItem(lastAuthUserKey);
      
                  if (lastAuthUserValue && lastAuthUserValue === username && !hasAllRequiredProperties) {
                    keysToRemove.add(lastAuthUserKey);
                  }
                }
              }
            }
          }
        });
    
    // Remove the collected keys outside the loop
    keysToRemove.forEach(k => localStorage.removeItem(k));
}   
  
export const logoutFromAmplifyState = () => {
    let foundLastAuthUserKey = ""
    for (let index = 0; index < STORAGE.length; index++) {
        const key = STORAGE.key(index)
        if (key?.endsWith(LAST_AUTH_USER)) { // there should be only be 1
            foundLastAuthUserKey = key
            break
        }
    }

    if (foundLastAuthUserKey) {
        const parts = foundLastAuthUserKey.split(".")
        parts.pop()
        const prefix = parts.join(".")
        for (let index = 0; index < STORAGE.length; index++) {
            const key = STORAGE.key(index)
            if (key?.startsWith(prefix)) {
                STORAGE.removeItem(key)
                logDebug("removed key from STORAGE on logout", key)
            }
        }
    }
}
  
