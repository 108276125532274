import { Component, OnInit, TRANSLATIONS } from '@angular/core'
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms'
import { Router } from '@angular/router'
import { AuthenticatorService } from '@aws-amplify/ui-angular'
import { Auth } from 'aws-amplify'
import { ReCaptchaV3Service } from 'ng-recaptcha'
import { logError } from '../../log'
import { TOAST_LEVELS, showToast } from "../../toast"
import { PARAMS } from '../PARAMS'
import { getSession } from '../amplify/amplify'
import { executeCaptchaAction, setCaptchaV3Service } from '../captcha'
import { REDWOOD_USER } from '../shared-worker-on-ui-thread'
import { initLogout, logout } from '../logout/logout'
import { TRANSLATION } from '../amplify/amplify_translations'
import { fetchPswPolicy } from '../cognito'

@Component({
  selector: 'app-alt-reset-psw',
  templateUrl: './alt-reset-psw.component.html',
  styleUrls: ['./alt-reset-psw.component.scss']
})
export class AltResetPswComponent implements OnInit {
  componentState = 'CHECK_USERNAME';
  // componentState = 'RESET_PASSWORD';
  changePasswordForm!: FormGroup
  errorMessage: string = ""
  user: any
  passwordPolicyOk = false

  checkUserNameForm = new FormGroup({
    userName: new FormControl(''),
    thepot: new FormControl('')
  });
  resetPasswordForm = new FormGroup({
    codeField: new FormControl(' '),
    newPasswordField: new FormControl(' '),
    confirmPasswordField: new FormControl(' '),
    thepot: new FormControl('')
  })

  constructor(
              public router: Router,
              private recaptchaV3Service: ReCaptchaV3Service
              ) {
                setCaptchaV3Service(recaptchaV3Service)
  }

  cancel() {
    this.router.navigateByUrl('/login');
  }

  async ngOnInit() {
    const decodedUri = new URL(decodeURI(window.location.href)).searchParams;
    const userName = decodedUri.get('username')
    if (userName) {
      this.checkUserNameForm.setValue({ userName, thepot: '' })
    }

    this.resetPasswordForm.setValue({ codeField: '', confirmPasswordField: '', newPasswordField: '', thepot: '' })

  }

  async sendCodeHandler(event: Event) {
    event.preventDefault()
    const userName = this.checkUserNameForm.get('userName')?.value
    const thepot = this.checkUserNameForm.get('thepot')?.value

    const body = JSON.stringify({
      commandType: 'SendCodeToEmailCommand',
      emailOrUsername: userName,
      captchaToken: await executeCaptchaAction("email_code_psw_reset"),
      thepot
    })
    try {
      const response = await fetch(`${PARAMS["REDWOOD-LAMBDA"]}`,{
        method: 'POST',
        body
      })
      const data = await response.json()
      if (data.success) {
        this.errorMessage = '';
        this.componentState = 'RESET_PASSWORD'
      } else {
        throw new Error(data.error);
      }

    }
    catch (error: any) {
      showToast(error.message, TOAST_LEVELS.ERROR)
    }
  }

  async resetPasswordHandler(event: Event) {
    event.preventDefault()
    if (!this.passwordPolicyOk) return
    const emailOrUsername = this.checkUserNameForm.get('userName')?.value
    const code = parseInt(this.resetPasswordForm.get('codeField')?.value as string)
    const newPassword = this.resetPasswordForm.get('newPasswordField')?.value
    const thepot  = this.resetPasswordForm.get('thepot')?.value

    const body = JSON.stringify({
      commandType: 'VerifyCodeToEmailCommand',
      code,
      emailOrUsername,
      newPassword,
      captchaToken: await executeCaptchaAction("reset_psw"),
      thepot
    })

    try {
      const response = await fetch(`${PARAMS["REDWOOD-LAMBDA"]}`,{
        method: 'POST',
        body
      })
      const data = await response.json()
      if (data.success) {
        showToast('Wachtwoord successvol gewijzigd!', TOAST_LEVELS.SUCCES)
        this.router.navigateByUrl('/login')

      } else {
        throw new Error(data.error);
      }

    }
    catch (error: any) {
      showToast(error.message, TOAST_LEVELS.ERROR)
    }
  }

  checkConfirm(event: Event) {
    const newPassword = this.resetPasswordForm.get('newPasswordField')?.value
    const confirmPassword = this.resetPasswordForm.get('confirmPasswordField')?.value

    if (newPassword?.trim() !== confirmPassword?.trim()) {
      this.errorMessage = 'Wachtwoorden komen niet overeen!'
    } else {
      this.errorMessage = ''
    }
  }

  async checkPasswordPolicy($event: Event | string) {
    const {
      MinimumLength,
      RequireUppercase,
      RequireLowercase,
      RequireNumbers,
      RequireSymbols
    } = await fetchPswPolicy()
    const newPassword = typeof $event === "string" ? $event : ($event as any)['target']['value']
    const errors = []

    if (MinimumLength && newPassword.length < MinimumLength) {
      errors.push(`Wachtwoord moet minimaal ${MinimumLength} tekens lang zijn.`)
    }

    if (RequireNumbers && !/\d/.test(newPassword)) {
      errors.push('Wachtwoord moet minimaal 1 cijfer bevatten.')
    }

    if (RequireSymbols && !/[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(newPassword)) {
      errors.push('Wachtwoord moet minimaal 1 speciaal teken bevatten.')
    }

    if (RequireUppercase && !/[A-Z]/.test(newPassword)) {
      errors.push('Wachtwoord moet minimaal 1 hoofdletter bevatten.')
    }

    if (RequireLowercase && !/[a-z]/.test(newPassword)) {
      errors.push('Wachtwoord moet minimaal 1 kleine letter bevatten.')
    }
    console.log({errors})

    this.errorMessage = errors.join("\n")

    this.passwordPolicyOk = errors.length === 0
  }

  async onSubmit() {
    const oldPassword = this.changePasswordForm?.value?.oldPassword
    const newPassword = this.changePasswordForm?.value?.newPassword
    if (oldPassword == newPassword) {
      showToast('Uw nieuw wachtwoord mag niet gelijk zijn aan het oude.', TOAST_LEVELS.ERROR)
      return
    }

    try {
      await this.checkPasswordPolicy(newPassword)
      if (!this.passwordPolicyOk) {
        return
      }

      const user = await Auth.currentAuthenticatedUser()
      const changePSwResult = await Auth.changePassword(user, oldPassword, newPassword)
      if (changePSwResult == "SUCCESS") {
        showToast('Paswoord successvol gewijzigd !', TOAST_LEVELS.SUCCES)
      } else {
        logError("Return value psw change", changePSwResult)
      }

      const captcha = await executeCaptchaAction('changePSW')
      let urlSearchParams = new URLSearchParams()
      const redwoodUser: any = REDWOOD_USER()
      urlSearchParams.append("email", redwoodUser.email)
      urlSearchParams.append("captcha", captcha)
      const response = await fetch(`${PARAMS.EMAIL_ON_PSW_CHANGE_LAMBDA}?${urlSearchParams}`, {
        headers: {
          "authorization" : `Bearer ${(await getSession()).access_token}`,
          "Content-Type": "application/json",
      } })
      if (response.status !== 200) {
        showToast("Confirmatie paswoord wijziging verzenden is gefaald. Dit staat los van de passwoord wijziging.", TOAST_LEVELS.ERROR, TRANSLATION.ERROR_TITLE)
      }
      const result = await response.text()
    } catch (e: any) {
      let msg = ""
      switch (e.code) {
        case "LimitExceededException":
          msg = "Teveel op elkaar volgende wachtwoordwijzigingen. Probeer het later eens opnieuw. We verlaten nu de sessie."
          break
        case "InvalidPasswordException":
          msg = "Uw oude wachtwoord is niet correct. We verlaten nu de sessie."
          break
        case "NotAuthorizedException":
          msg = "Uw oude wachtwoord is niet correct. We verlaten nu de sessie."
          const error = e?.message
          if (error?.includes("not conform with policy")) {
            msg = "Uw wachtwoord voldoet niet aan de regels."
          }
          break
      }

      showToast(msg, TOAST_LEVELS.ERROR, TRANSLATION.ERROR_TITLE)
      logError('Error changing password:', e)
      this.errorMessage = msg
    } finally {
      if (this.passwordPolicyOk) {
        setTimeout(async () => {
          initLogout()
          await logout()
        }, 3500)
      }
    }

  }
}
