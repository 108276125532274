import { Component } from '@angular/core'
import { Auth } from 'aws-amplify'
import { IPool, PARAMS, POOL_NAMES, getDefaultPool } from '../PARAMS'
import { AUTH$, AUTH_EVENTS, initAmplify } from '../amplify/amplify'
import { AllowedParentOrigin, inIFrame } from '../inIFrame'
import { STORAGE } from '../server-storage'
import { POOL_ID_KEY, setPoolId } from '../amplify/amplify_state'
import { log, logError } from '../../log'
import { QS_REDIRECT_URI, REDIRECT_URL_ON_STARTUP } from '../../main'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  params = PARAMS
  username = ""
  logo = getDefaultPool().LOGO
  user: any


  async ngOnInit() {
    this.user = await Auth.currentAuthenticatedUser()
    AUTH$.subscribe(async (data: { payload: any }) => {
      const { payload } = data
      log("hub received", data)
      try {
        switch(payload.event) {
          case AUTH_EVENTS.signIn:
            this.user = await Auth.currentAuthenticatedUser()
            break
          case AUTH_EVENTS.signOut:
            this.user = undefined
            break
        }
      } catch(e) {
        logError(e)
      }
    })
  }

  async setUser(user: any) {
    if (user) {
      this.user = await Auth.currentAuthenticatedUser()
    }
  }

  async openUrl(pool: any) {
    let url = pool.force_logout_url || pool.URL
    if (pool.force_logout_url) delete pool.force_logout_url
    setPoolId(pool.USER_POOL_ID)
    const sp = new URLSearchParams(location.search)
    if (sp.has(QS_REDIRECT_URI)) {
      localStorage[REDIRECT_URL_ON_STARTUP] = sp.get(QS_REDIRECT_URI)
    }

    log(url)
    switch(pool.NAME) {
      case POOL_NAMES.GOOGLE:  // intentional fallthrough !!
      case POOL_NAMES.TEST_GOOGLE: 
        STORAGE.setItem(POOL_ID_KEY, pool.USER_POOL_ID)
        location.href = pool.URL
        return
        break
      case POOL_NAMES.LEER_ID:
      case POOL_NAMES.TEST_LEER_ID:
        await initAmplify(pool.USER_POOL_ID)
        //await Auth.federatedSignIn({ customProvider: pool.NAME })
        location.href = pool.URL
        
        break
      case POOL_NAMES.SMARTSCHOOL:
      case POOL_NAMES.TEST_SMARTSCHOOL:  // intentional fallthrough 
        location.href = pool.URL
    }

  }

  get pools(): IPool[] {
    return PARAMS.POOLS.filter(p => !p.DEFAULT)
  }

}



