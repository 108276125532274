export const log = (...msg: any[]) => {
  const d = new Date()
  console.log(`${d.toLocaleDateString()} ${d.toLocaleTimeString()}`, ...msg)
}

export const logError = (...msg: any[]) => {
  const d = new Date()
  console.error(`${d.toLocaleDateString()} ${d.toLocaleTimeString()}`, ...msg)
}

export const logWarning = (...msg: any[]) => {
  const d = new Date()
  console.warn(`${d.toLocaleDateString()} ${d.toLocaleTimeString()}`, ...msg)
}

export const logInfo = (...msg: any[]) => {
  const d = new Date()
  console.info(`${d.toLocaleDateString()} ${d.toLocaleTimeString()}`, ...msg)
}

export const logDebug = (...msg: any[]) => {
  const d = new Date()
  console.debug(`${d.toLocaleDateString()} ${d.toLocaleTimeString()}`, ...msg)
}