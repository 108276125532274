import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QS_REDIRECT_URI } from '../../main';
import { initLogout, logout } from './logout';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  
  constructor(private router: Router
    ) {
  }
  
  async ngOnInit() {
    initLogout()
    setTimeout(async() => {
      await logout()  
    }, 2000)
  }

  toLogin() {
    const qs = new URLSearchParams(location.search)
    let url = "/login"
    if (qs.has(QS_REDIRECT_URI)) {
      const queryParams = {
        redirect_uri: qs.get(QS_REDIRECT_URI)
      }
      url = this.router.createUrlTree(['/login'], { queryParams }).toString()
    } 
    this.router.navigateByUrl(url)
  }
}
