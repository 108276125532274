import { POOL_NAMES } from "./app/PARAMS"
import { getClientId, getPool } from "./app/amplify/amplify_state"
import { logDebug, logError, logInfo } from "./log"
import { exchangeCodeForLeerIDToken } from "./pools/leerid"
import { exchangeCodeForSmartschoolToken } from "./pools/smartschool"

export const exchangeCodeForToken = async (code: string): Promise<any> => {
    try {
      const pool = getPool()
      const qs = new URLSearchParams()
      let url: string, paramsObj: any, searchParams: any, result: any, response: any
      switch (pool?.NAME) {
        case POOL_NAMES.SMARTSCHOOL: // INTENTIONAL FALLTHROUGH
        case POOL_NAMES.TEST_SMARTSCHOOL:
          return await exchangeCodeForSmartschoolToken(code)
        case POOL_NAMES.LEER_ID:  // INTENTIONAL FALLTHROUGH
        case POOL_NAMES.TEST_LEER_ID:
          return await exchangeCodeForLeerIDToken(code, pool)
          break
        default:
          paramsObj = {
            client_id: getClientId(),
            redirect_uri: location.origin,
            "Content-Type": "application/x-www-form-urlencoded",
            code,
            grant_type: "authorization_code",
            Authorization: "Basic"
          }
  
          logDebug(paramsObj)
          searchParams = new URLSearchParams(paramsObj)
          url = pool?.TOKEN_URL || (pool?.DOMAIN + "/oauth2/token")
          logInfo("url", url)
          response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: searchParams.toString()
          })
  
          result = await response.json()
          logDebug("exchangeCodeForToken success:", result)
  
          if (result.error) {
            throw new Error(result.error)
          }
  
          return result
      }
    } catch (error) {
      logError("exchangeCodeForToken Error:", error)
      throw error
    }
  }