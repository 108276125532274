export const POOL_NAMES = {
    SMARTSCHOOL: "smartschool",
    GOOGLE : "Google",
    TEST_GOOGLE : "test-google",
    E_DUCATE_ME: "e-ducate.me",
    LEER_ID: "leerid",
    TEST_LEER_ID: "test_leer_id",
    TEST_E_DUCATE_ME : "e-ducate.me",
    TEST_SMARTSCHOOL: "test-smartschool"
}

const SORT_ORDER = {
    EDUCATE_ME: 0,
    LEER_ID: 1,
    SMARTSCHOOL: 2,
    GOOGLE: 3
}

export const isProd = (): boolean => {
    return location.origin.includes("e-ducate.me")
}

export interface IPool {
    INDEX: number,
    USER_POOL_ID: string,
    CLIENT_ID: string,
    NAME: string,
    LOGO: string,
    URL: string,
    DOMAIN: string,
    DISPLAY_NAME: string,
    TOKEN_URL?: string
    DEFAULT?: boolean,
    SCOPE?: string[],
    IDP_ENDPOINT?: string,
}

export interface IPARAMS  {
    RECAPTCHA: string,
    "REDWOOD-LAMBDA": string,
    REDWOOD_UI: string,
    SMARTSCHOOL_LAMBDA: string,
    EMAIL_VALIDATOR_LAMBDA: string,
    LEERID_LAMBDA: string,
    GOOGLE_LAMBDA: string,
    SETUP_MFA_LAMBDA: string,
    SSO_TOKEN_LAMBDA: string,
    ENVIRONMENT: string,
    SERVER_STORAGE_LAMBDA: string,
    EMAIL_ON_PSW_CHANGE_LAMBDA: string,
    EDUCATEME_LAMBDA: string,
    POOLS: IPool[],
    PSW_POLICY_LAMBDA: string
    OAUTH_LAMBDA: string
}

export const PARAMS: IPARAMS = {
    "RECAPTCHA": "6LfCxW8mAAAAADmO0-SYGR6qsQLFvX8P5uRGY6rb",
    "REDWOOD-LAMBDA": "https://redwood-lambda.sharklazers.be",
    "REDWOOD_UI": "https://redwood.sharklazers.be",
    "SMARTSCHOOL_LAMBDA": "https://42jptndhjfyqg4j3vtp4o5vxtu0wofjo.lambda-url.eu-central-1.on.aws",
    "LEERID_LAMBDA": "https://y4fqjjcfpfkrqq3qqxuniydrf40zmkef.lambda-url.eu-central-1.on.aws",
    "SETUP_MFA_LAMBDA": "https://ojmxucz7xootzsedsgg7n4xg3u0javtw.lambda-url.eu-central-1.on.aws",
    "SERVER_STORAGE_LAMBDA": "https://kzncosmyipx5x6wiqn4luyrcxy0rmouk.lambda-url.eu-central-1.on.aws",
    "GOOGLE_LAMBDA": "https://tmeyekynah4udgjeei3czvnnfy0mfiht.lambda-url.eu-central-1.on.aws",
    "SSO_TOKEN_LAMBDA": "https://yualrwfdnjjrrk7lblur3aawyq0obdlg.lambda-url.eu-central-1.on.aws",
    "EMAIL_VALIDATOR_LAMBDA": "https://yutdzndug35eahydxdtq2hktua0cagmd.lambda-url.eu-central-1.on.aws",
    "EMAIL_ON_PSW_CHANGE_LAMBDA": "https://okzwsavf5ln6z2sym3xnt3ewni0vneer.lambda-url.eu-central-1.on.aws",
    "PSW_POLICY_LAMBDA": "https://bgwse7siczzvltabedj64dkbca0agwqj.lambda-url.eu-central-1.on.aws",
    "EDUCATEME_LAMBDA": "https://bf72jymu6hbiisyaqzz6jqcyfq0ujkss.lambda-url.eu-central-1.on.aws",
    "OAUTH_LAMBDA": "https://oauth-lambda.sharklazers.be",
    "ENVIRONMENT": "test",
    "POOLS": []
};

export const findPoolById = (poolId: string): IPool | undefined=> {
    return PARAMS.POOLS.find(p => p.USER_POOL_ID === poolId)
}

export const findPoolByClientId = (clientId: string): IPool | undefined => {
    return PARAMS.POOLS.find(p => p.CLIENT_ID === clientId)
}

export const findPoolByName = (name: string): IPool | undefined => {
    return PARAMS.POOLS.find(p => p.NAME === name)
}

export const getDefaultPool = (): IPool => {
    return PARAMS.POOLS.find(p => p.DEFAULT) as IPool
}

export const deletePoolByName = (name: string) => {
    let idx = 0
    for (const p of PARAMS.POOLS) {
        if (p.NAME === name) {
            PARAMS.POOLS.splice(idx, 1)
            break
        }
        idx ++
    }
}

export const initParams = () => {
    if (isProd()) {
        PARAMS["REDWOOD-LAMBDA"] = "https://redwood-lambda.e-ducate.me"
        PARAMS.ENVIRONMENT = "prod"
        PARAMS.REDWOOD_UI = "https://redwood.e-ducate.me"
        PARAMS.OAUTH_LAMBDA = "https://b2wbomu74pskihhtx3x76bh7oa0vntpo.lambda-url.eu-central-1.on.aws"
        PARAMS.POOLS.push({
            INDEX: SORT_ORDER.EDUCATE_ME,
            USER_POOL_ID: "eu-central-1_sQzAPqv0c",
            CLIENT_ID: "i2mau8rlfknuunt2mash6u16r",
            NAME: POOL_NAMES.E_DUCATE_ME,
            DISPLAY_NAME: POOL_NAMES.E_DUCATE_ME,
            LOGO: "assets/educatemelijnprintwit.svg",
            URL: `${location.origin}/amplify-login`,
            DOMAIN: "https://e-ducate-me.auth.eu-central-1.amazoncognito.com",
            DEFAULT: true,
            SCOPE:[]
        })
        PARAMS.POOLS.push({
            INDEX: SORT_ORDER.SMARTSCHOOL,
            USER_POOL_ID: 'eu-central-1_hId2wUTH7',
            CLIENT_ID: '7e0q2g830k03pes0nrdl7mek88',
            NAME: POOL_NAMES.SMARTSCHOOL,
            LOGO: "assets/smartschool_app_icon.svg",
            URL: `https://oauth.smartschool.be/OAuth/index/platformchooser?client_id=e8d872cbff27`,
            DOMAIN: "https://smartschool.auth.eu-central-1.amazoncognito.com",
            DISPLAY_NAME: "Smartschool",
            TOKEN_URL: "https://oauth.smartschool.be/OAuth/index/token",
            SCOPE: [
                'userinfo',
                'fulluserinfo',
                'groupinfo'
            ],
            IDP_ENDPOINT: "https://oauth.smartschool.be"
        })
        PARAMS.POOLS.push({
            INDEX: SORT_ORDER.LEER_ID,
            USER_POOL_ID: 'eu-central-1_sXN52OAh4',
            CLIENT_ID: '4gvd1cpjklvb2so6n7iuve9pd6',
            NAME: POOL_NAMES.LEER_ID,
            LOGO: "assets/LeerID_pos_kl_36x36.svg",
            URL: "https://leerid.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=LeerIDIDP&login_hint=eyJzd2l0Y2hfaWQiOiB0cnVlfQ==",
            DOMAIN: "https://leerid.auth.eu-central-1.amazoncognito.com",
            DISPLAY_NAME: "LeerID",
            SCOPE: [
                'openid',
                'profile',
                'users-resource-srv/ov_leerling',
            ],
            IDP_ENDPOINT: "https://authenticatie.vlaanderen.be"
        })
    } else { // Test Sharklazers
        PARAMS.POOLS.push({
            INDEX: SORT_ORDER.EDUCATE_ME,
            USER_POOL_ID: "eu-central-1_JfYWzCjdQ",
            CLIENT_ID: "21lru4bh50a7j1b5llaoptn9qp",
            NAME: POOL_NAMES.TEST_E_DUCATE_ME,
            DISPLAY_NAME: POOL_NAMES.TEST_E_DUCATE_ME,
            LOGO: "assets/educatemelijnprintwit.svg",
            URL: `${location.origin}/amplify-login`,
            DOMAIN: "https://test-e-ducate-me-userpool.auth.eu-central-1.amazoncognito.com",
            DEFAULT: true,
            SCOPE: []
        })
        PARAMS.POOLS.push({
            INDEX: SORT_ORDER.LEER_ID,
            USER_POOL_ID: 'eu-central-1_cPB1FpoEK',
            CLIENT_ID: '3h3mh979l5d0lr6fv84i0p7av1',
            NAME: POOL_NAMES.TEST_LEER_ID,
            LOGO: "assets/LeerID_pos_kl_36x36.svg",            
            URL: "https://test-leer-id.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=TestLeerID&login_hint=eyJzd2l0Y2hfaWQiOiB0cnVlfQ==",
            DOMAIN: "https://test-leer-id.auth.eu-central-1.amazoncognito.com",
            DISPLAY_NAME: "Test LeerID",
            SCOPE: [
                'openid',
                'profile',
                'aws.cognito.signin.user.admin'
                //'vo'
                //'ov_leerling',
                //'email'
            ],
            IDP_ENDPOINT: "https://authenticatie-ti.vlaanderen.be"
        })
        PARAMS.POOLS.push({
            INDEX: SORT_ORDER.GOOGLE,
            USER_POOL_ID: "eu-central-1_E3E0apjOQ",
            CLIENT_ID: '65lk7pd4p4ccrl5u37ts9a59or',
            NAME: POOL_NAMES.TEST_GOOGLE,
            LOGO: "assets/Google__G__Logo.svg",
            "URL": `https://test-google-edume.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&response_type=CODE`,
            DOMAIN: "https://test-google-edume.auth.eu-central-1.amazoncognito.com",
            DISPLAY_NAME: "Test Google",
            SCOPE: [
                'openid',
                'email',
                'phone'
            ],
            IDP_ENDPOINT: "https://mail.google.com/mail/u/0/"
        })
        PARAMS.POOLS.push(
            {
                INDEX: SORT_ORDER.SMARTSCHOOL,
                USER_POOL_ID: 'eu-central-1_QaFeVlz8e',
                CLIENT_ID: '52anoccnn509lseja8ac51ufhn',
                NAME: POOL_NAMES.TEST_SMARTSCHOOL,
                LOGO: "assets/smartschool_app_icon.svg",
                URL: `https://oauth.smartschool.be/OAuth/index/platformchooser?client_id=e8d872cbff27`,
                DOMAIN: "https://test-smartschool.auth.eu-central-1.amazoncognito.com",
                DISPLAY_NAME: "Test Smartschool",
                TOKEN_URL: "https://oauth.smartschool.be/OAuth/index/token",
                SCOPE: [
                    'userinfo',
                    'fulluserinfo',
                    'groupinfo'
                ],
                IDP_ENDPOINT: "https://oauth.smartschool.be"
            }
        )
    }

    PARAMS.POOLS = PARAMS.POOLS.sort((a, b) => a.INDEX - b.INDEX)
    for (const p of PARAMS.POOLS) {
        const url = new URL(p.URL)
        const CLIENT_ID = "client_id"
        if (!url.searchParams.has(CLIENT_ID)) {
            url.searchParams.set(CLIENT_ID, p.CLIENT_ID)
        }
        const REDIRECT_URI = "redirect_uri"
        if (!url.searchParams.has(REDIRECT_URI)) {
            url.searchParams.set(REDIRECT_URI, location.origin)
        }
        const RESPONSE_TYPE = "response_type"
        if (!url.searchParams.has(RESPONSE_TYPE)) {
            url.searchParams.set(RESPONSE_TYPE, "code")
        }
        const SCOPE = "scope"
        if (!url.searchParams.has(SCOPE) && p.SCOPE?.length) {
            url.searchParams.set(SCOPE, p.SCOPE?.join(" "))
        }
        p.URL = url.toString()
    }
}

