<div class="container">
    <form class="centered-content amplifyBorders" data-amplify-form>

        <div *ngIf="MFAEnabled"  class="form-group">
            <button type="button" *ngIf="user" (click)="disable_MFA()">Deactiveer MFA</button>
        </div>
        <div *ngIf="!MFAEnabled">
            <h3 class="form-group">Activeer MFA</h3>
            <p class="form-group"> (MFA) is een methode om de authenticiteit van een gebruiker te verifiëren (authenticatie) op meer dan één enkele manier (met behulp van meerdere factoren). <br/>
                Onze tweede factor is een code via een Authenticator app. <br/>
                Door meerdere factoren te combineren kan de beveiliging bij toegangscontrole worden aangescherpt.<br/>
            </p>
            <p class="form-group">Klik op de knop om MFA QR code te genereren. <br>
            Scan de code met <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=nl&gl=US&pli=1" target="_blank">Google Authenticator</a> of <a href="https://www.microsoft.com/nl-be/security/mobile-authenticator-app" target="_blank">Microsoft Authenticator</a> of <a href="https://duo.com/product/multi-factor-authentication-mfa/duo-mobile-app">Cisco duo mobile</a> of een andere Authenticator app van jouw keuze op je smartphone</p>
            <div class="form-group buttons" *ngIf="currentStep === 0">
              <button type="button" class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" style="font-weight: normal;"  (click)="get_qr()" [disabled]="isDisabled">Maak mijn MFA QR Code</button>
              <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--destructive" (click)="cancel()">Annuleren</button>
            </div>
            <div class="form-group" *ngIf="qrCodeImageUrl && currentStep === 1">
                <img [src]="qrCodeImageUrl" style="width: 400px; height: 350px;" />
            </div>
            <div *ngIf="qrCodeImageUrl && currentStep === 1">
                <div class="form-group">
                    <p>Geef hier de actuele code in die nu op je gekozen Authenticator App verschijnt.</p>
                    <label class="amplify-label" for="verification_code">code</label>
                    <input  class="amplify-input" type="number" (input)="input($event)" [(ngModel)]="verification_code" name="verification_code">
                </div>
                <div class="form-group buttons">
                  <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" *ngIf="isCodeSixDigits" (click)="verify_mfa()">verifiëer code</button>
                  <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--destructive" (click)="cancel()">Annuleren</button>
                </div>
            </div>
            <div class="form-group buttons" *ngIf="qrCodeImageUrl && currentStep === 2">
              <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary"  (click)="finalize_mfa()">Finaliseer MFA</button>
              <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--destructive" (click)="cancel()">Annuleren</button>
            </div>
        </div>
    </form>
</div>
