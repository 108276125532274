import { Hub } from '@aws-amplify/core'
import { AuthenticatorService, translations } from '@aws-amplify/ui-angular'
import { Amplify, Auth, I18n, Logger } from 'aws-amplify'
import { Subject } from 'rxjs'
import { log, logDebug, logError, logWarning } from '../../log'
import { isMAPPING_USER } from '../../main'
import { hideSpinner, isSpinning, showSpinner } from '../../spinner'
import { TOAST_LEVELS, showToast } from "../../toast"
import { clearRedirectUriOnStartup, getTokenRefreshInterval, handleRedirectURI, reInitAmplify } from '../../utils'
import { EVENTS } from '../EVENTS'
import { ISession } from '../ISession'
import { IPool, PARAMS, POOL_NAMES, findPoolByClientId, findPoolById, getDefaultPool } from '../PARAMS'
import { getClientId, getPoolId, saveAmplifyStateOnServer, setPoolId } from './amplify_state'
import { AMPLIFY_TRANSLATIONS } from './amplify_translations'
import { LOGOUT_FROM_POOL_ID_KEY, LOGOUT_STEP_KEY, THIRD_PARTY_LOGOUT_URL_KEY, logout } from '../logout/logout'
import { handleMapping, setIsSignup } from '../mapping'
import { parseJwt } from '../parseJWT'
import { STORAGE } from '../server-storage'
import { REDWOOD_USER, SHARED_WORKER, initSharedWorker, postMsg2ChannelOrWorker } from '../shared-worker-on-ui-thread'
import { setNeedsActivationInRedwood, expireRedwoodSessionCookie, redirect2RedwoodForActivation, REDWOOD_ACTIVATION_URL, doubleCheckNeedsActivation } from '../../redwood'
import { cognitoSession2User } from '../cognito'

Logger.LOG_LEVEL = 'DEBUG'

export const HUB_CHANNEL_NAME = "auth"

export let signUpOrInUsername = ""
export let poolName = ""
export const AUTH_EVENTS = {
  signIn: "signIn",
  signOut: "signOut",
  signUp: "signUp",
  signUp_failure: "signUp_failure",
  confirmSignUp: "confirmSignUp",
  signIn_failure: "signIn_failure",
  autoSignIn: "autoSignIn",
  completeNewPassword: "completeNewPassword",
  completeNewPassword_failure: "completeNewPassword_failure",
  updateUserAttributes: "updateUserAttributes",
  updateUserAttributes_failure: "updateUserAttributes_failure",
  tokenRefresh: "tokenRefresh",
  tokenRefresh_failure: "tokenRefresh_failure",
  codeFlow: "codeFlow",
  forgotPasswordSubmit_failure: "forgotPasswordSubmit_failure"
}
export let oldPool: IPool | undefined

let NOMFAToastShown = false
export let current_auth_event: string
let currentPool: IPool | undefined
let currentUser: any
export let IGNORE_EVENTS = false
export const setIGNORE_EVENTS = (value: boolean) => {
  IGNORE_EVENTS = value
  logDebug('IGNORE_EVENTS', IGNORE_EVENTS)
}
export const AUTH$ = new Subject<any>()
export let LAST_PAYLOAD: any

export const putTokenOnSrv = async (token: string) => {
  const body = JSON.stringify({ token })
  const response = await fetch(PARAMS.SSO_TOKEN_LAMBDA, { method: "PUT", body })
  const result = await response.json()

  return result
}

const translateMessage = (payload: any) => {
  const translation = (AMPLIFY_TRANSLATIONS.nl as any)[payload.data.message as string]
  if (translation) {
    payload.data.message = translation
  } else {
    const prefix = "PreSignUp failed with error"
    if (payload.data.message.startsWith(prefix)) {
      logWarning(payload.data.message)
      payload.data.message = payload.data.message.substring(prefix.length).trim()
    }
  }
}

export const authListener =  async (data: { payload: any }) => {
  if (IGNORE_EVENTS) return
  AUTH$.next(data)
  const { payload } = data
  LAST_PAYLOAD = payload
  log("hub received", data)

  try {
    switch(payload.event) {
      case AUTH_EVENTS.signIn:
        clearRedirectUriOnStartup()
        signUpOrInUsername = payload.data?.username
        poolName = findPoolById(payload.data.pool?.userPoolId)?.NAME as string
        const isEducateMePool = poolName === POOL_NAMES.E_DUCATE_ME || poolName === POOL_NAMES.TEST_E_DUCATE_ME
        if (isMAPPING_USER() && isEducateMePool) { // isEducateMePool avoids re-entrancy when already mapping
          showSpinner(`start mapping gebruiker.`)
          await handleMapping(payload)
        }
        log('User has successfully signed in:', payload.data)
        setPoolId(currentPool?.USER_POOL_ID as string)
        const ru = await cognitoSession2User(payload.data.signInUserSession.idToken.jwtToken)
        if (ru?.needs_activation) {
            const still_needs_activation = await doubleCheckNeedsActivation(ru.username) // by-pass cache
            await Auth.currentAuthenticatedUser()
            if (still_needs_activation) redirect2RedwoodForActivation()
        } else if (ru) {
          REDWOOD_USER.set(ru)
        }
        await postMsg2ChannelOrWorker({
            event: EVENTS.startTimer,
            username: payload.data.username,
            userPoolId: currentPool?.USER_POOL_ID,
            clientId: currentPool?.CLIENT_ID,
            refresh_token: payload.data.signInUserSession?.refreshToken.token,
            access_token: payload.data.signInUserSession?.accessToken.jwtToken,
            id_token: payload.data.signInUserSession?.idToken.jwtToken,
            refresh_interval: getTokenRefreshInterval(),
            location: location.origin,
            userAttributes: payload.data.attributes,
            oauth_lambda_url : PARAMS.OAUTH_LAMBDA
        })
        if (isEducateMePool) {
          if (payload.data.preferredMFA === "NOMFA" && !NOMFAToastShown) {
            NOMFAToastShown = true
            showToast("U kan uw account beter beschermen door 2 fase authenticatie te activeren. <br/> Klik op <a href='./mfa'>Beheer MFA knop<a/> in het beheerscherm.", TOAST_LEVELS.WARN, "Beveilings notificatie")
          }
        }
        // ensure no rest from logout procedure
        localStorage.removeItem(LOGOUT_STEP_KEY)
        localStorage.removeItem(LOGOUT_FROM_POOL_ID_KEY)
        localStorage.removeItem(THIRD_PARTY_LOGOUT_URL_KEY)
        //expireRedwoodSessionCookie()
        handleRedirectURI()
        break
      case AUTH_EVENTS.signOut:
        //alert(`signout amplify`)
        NOMFAToastShown = false
        if (data.payload.data) {
          oldPool = findPoolByClientId(data.payload.data?.pool?.clientId)
        }
        if (current_auth_event === payload.event) {
          logWarning(`${payload.event} already handled.`)
          break
        }
        postMsg2ChannelOrWorker({
            event: "logout"
        })
        break
      case AUTH_EVENTS.signUp:
        NOMFAToastShown = false
        signUpOrInUsername = payload.data.user.username
        if (isMAPPING_USER()) {
          setIsSignup(true)
        }
        const poolId = payload.data?.user?.pool?.userPoolId
        if (poolId)
          poolName = findPoolById(poolId)?.NAME as string
        else logError(`poolId not found`)
        break
      case AUTH_EVENTS.signUp_failure: 
        translateMessage(payload)
        break 
      case AUTH_EVENTS.confirmSignUp:
        signUpOrInUsername = ""
        if (isMAPPING_USER()) {
          setIsSignup(true)
        }
        setNeedsActivationInRedwood(payload)
        reInitAmplify()
        break
      case AUTH_EVENTS.signIn_failure:
        if (payload?.data?.code == "UserNotFoundException") {
          payload.data.message = (AMPLIFY_TRANSLATIONS.nl as any)["UserNotFoundException"]
        }
        if (isSpinning()) {
         if (payload?.message) showSpinner(payload.message, true)
         if (isMAPPING_USER()) {
          setTimeout(() => {
            hideSpinner()
          }, 1500)
         }
        }
        break
      case AUTH_EVENTS.tokenRefresh:
        const session = await getSession()
        logDebug("AUTH_EVENTS.tokenRefresh session and payload", session, payload)
        const response = await putTokenOnSrv(session?.access_token as string)
        const parsedIdToken = parseJwt(session.id_token as string) as any
        await postMsg2ChannelOrWorker({
          event: EVENTS.startTimer,
          username: parsedIdToken?.username || parsedIdToken["cognito:username"],
          userPoolId: getPoolId(),
          clientId: getClientId(),
          refresh_token: session.refresh_token,
          access_token: session.access_token,
          id_token: session.id_token,
          issuer: parsedIdToken?.iss,
          jsonUser: session.user ? JSON.stringify(session.user) : undefined,
          refresh_interval: getTokenRefreshInterval(),
          location: location.origin,
          userAttributes: session?.user,
          oauth_lambda_url : PARAMS.OAUTH_LAMBDA
        }, session?.user)
        break
      case AUTH_EVENTS.tokenRefresh_failure:
        logError("Token refresh failure ...")
        await logout()
        break
      case AUTH_EVENTS.codeFlow:
        console.log(payload)
        break
      case AUTH_EVENTS.forgotPasswordSubmit_failure:
        translateMessage(payload)
        break
      default:
    }
  } catch(e) {
    logError("Hub.listen(HUB_CHANNEL_NAME)", e)
  } finally {
    current_auth_event = payload.event
  }
}

export const initAmplify = async (poolId?: string) => {
  if (currentPool && currentPool.USER_POOL_ID === poolId) {
    logWarning(`Pool ${currentPool?.NAME} id: ${currentPool?.USER_POOL_ID} already initialized`)

    return
  }
  if (currentPool) {
    Hub.remove(HUB_CHANNEL_NAME, authListener) //TODO: check is this really needed ?
  }
  
  let userPoolId = poolId
  if (!userPoolId) userPoolId = getPoolId() as string
  if (userPoolId) {
    currentPool = findPoolById(userPoolId)
  }
  if (!currentPool) {
    currentPool = getDefaultPool()
    setPoolId(currentPool?.USER_POOL_ID)
    userPoolId = currentPool?.USER_POOL_ID
  }

  logDebug("Amplify cfg'ed to pool", currentPool?.NAME)

  const isSmartSchool = [POOL_NAMES.SMARTSCHOOL, POOL_NAMES.TEST_SMARTSCHOOL].includes(currentPool.NAME)

  Amplify.configure({
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

      // REQUIRED - Amazon Cognito Region
      region: 'eu-central-1',

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'XX-XXXX-X',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: currentPool?.CLIENT_ID,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,

      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: location.hostname.replace("sso", ""),
        // OPTIONAL - Cookie path
        path: '/',
        // OPTIONAL - Cookie expiration in days
        expires: 365,
        // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: 'lax',
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true,
      },

      // OPTIONAL - customized storage object
      storage: STORAGE,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: isSmartSchool ? 'CUSTOM_AUTH': 'USER_PASSWORD_AUTH',

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      clientMetadata: {"origin": location.origin},

      // OPTIONAL - Hosted UI configuration

      oauth: {
        domain: new URL(currentPool?.DOMAIN as string).hostname,
        scope: currentPool?.SCOPE,
        redirectSignIn: location.origin,
        redirectSignOut: location.origin + "/logout",
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        clientId: currentPool?.CLIENT_ID
      },

    },
  })

  // You can get the current config object
  const currentConfig = Auth.configure()
  let recaptchaPromise: Promise<any> | undefined

  Hub.listen(HUB_CHANNEL_NAME, authListener)
  
  I18n.putVocabularies(translations)
  I18n.setLanguage('nl')
  I18n.putVocabularies(AMPLIFY_TRANSLATIONS)
}

export const checkUserIsSignIn = async () : Promise<boolean> => {
  try {
    const user = await Auth.currentAuthenticatedUser()

    return true
  } catch (error) {

    return false
  }
}

export const getSession = async(): Promise<ISession> => {
  try { 
    const user = await Auth.currentAuthenticatedUser()
    const { signInUserSession } = user 

    return {
      user: REDWOOD_USER(),
      username: user.username,
      id_token: signInUserSession.idToken.jwtToken,
      access_token: signInUserSession.accessToken.jwtToken,
      refresh_token: signInUserSession.refreshToken.token,
      client_id : user.pool.clientId,
      user_attributes: user.attributes
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getCurrentAmplifyUser = async (refresh: boolean = false): Promise<any> => {
  try {
    if (!currentUser || refresh) {
      currentUser = await Auth.currentAuthenticatedUser()
    }

    return currentUser
  } catch(e) {
    return undefined
  }
}

export const setOldPool = (value: IPool | undefined) => {
  oldPool = value
}



