import { IJWT } from "../IJWT"
import { IPool, PARAMS } from "../app/PARAMS"
import { getClientId, saveAmplifyStateOnServer } from "../app/amplify/amplify_state"
import { STORAGE } from "../app/server-storage"
import { log, logDebug, logError, logInfo } from "../log"
import { USER_MAPPING_INFO_KEY } from "../main"
import { IOnTokenResult } from "./common"

export const userInfoLeerID = async (pool: IPool, access_token: string) => {
    try {
      const response = await fetch(`${pool.DOMAIN}/oauth2/userInfo`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${access_token}`
        }
      })
      const data = await response.json()
  
      return data
    } catch (error) {
      logError("userInfoLeerID Error:", error)
      throw error
    }
}
  
export const getUserFromLeerIDMap = async (user_id: string, token: string) => {
    try {
      const urlSearchParams = new URLSearchParams()
      urlSearchParams.append("leerid_id", user_id)
      urlSearchParams.append("token", token)
      logDebug("getUserFromLeerIDMap", urlSearchParams)
      const response = await fetch(`${PARAMS.LEERID_LAMBDA}?${urlSearchParams}`)
  
      const data = await response.json()
      if (data && JSON.stringify(data) === "{}") {
        return undefined
      }
  
      return data
    } catch (error) {
      logError("getUserFromLeerIDMap Error:", error)
      throw error
    }
}

const putUserInfo = async(userInfo: any, leerid_id: string, access_token: string) => {
    try {
      const body = {
        "leerid_id": leerid_id,
        ... userInfo,
        token: access_token
      }
  
      const response = await fetch(PARAMS.LEERID_LAMBDA, {
        method: "put",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify(body)
      })
  
      const data = await response.json()
      if (data && JSON.stringify(data) === "{}") {
        return undefined
      }
  
      return data
    } catch (error) {
      logError("putUserInfo Error:", error)
      throw error
    }
}

const getUserInfoIDP = async (pool: IPool, token: string) => {
  try {
    const response = await fetch(`${pool.IDP_ENDPOINT}/op/v1/userinfo`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    const data = await response.json()

    return data
  } catch (error) {
    logError("userInfoLeerID Error:", error)
    throw error
  }
}

export const onLeerIDToken = async(session: any, pool: IPool, parsedJWT: IJWT | undefined, mappedUser: any): Promise<IOnTokenResult> => {
    log("leerid succesvol session", session)
    let userInfo
    try {
      userInfo = await userInfoLeerID(pool, session.access_token)
      log("leerid userInfo", userInfo)
      const idpResult = await getUserInfoIDP(pool, userInfo["custom:access_token"])
      log("IDP UserInfo endpoint result", JSON.stringify(idpResult))
      Object.assign(userInfo, idpResult)
      await putUserInfo(userInfo, parsedJWT?.sub as string, session.access_token)
    } catch (e) {
      logError("retrieve or put Userinfo failed", e)
    }
    logDebug("before destination_id")
    const destination_id = parsedJWT?.sub as string
    logDebug("destination_id", destination_id)
    mappedUser = await getUserFromLeerIDMap(destination_id, session?.access_token)

    if (!mappedUser) {
      log("no mapped user");
      STORAGE.setItem(USER_MAPPING_INFO_KEY, JSON.stringify({
        "destination_id": destination_id,
        "pool": pool.NAME,
        "token": session?.access_token,
        userInfo,
        session
      }))
      //location.href = `${location.origin}/map`
      history.replaceState({}, document.title, `${location.origin}/map`)
    }
    // HACK: push received token + user into amplify, by storing it in is state en restarting it
    await saveAmplifyStateOnServer(pool, userInfo, session, userInfo.username)
  
    return { exit : false, mappedUser, needsActivation: mappedUser?.needs_activation }
  }

export const exchangeCodeForLeerIDToken = async (code: string, pool: IPool) => {
    const paramsObj: any = {
        client_id: getClientId(),
        redirect_uri: location.origin,
        "Content-Type": "application/x-www-form-urlencoded",
        code,
        grant_type: "authorization_code",
        scope: pool.SCOPE?.join(" ")
      }

      if (sessionStorage.getItem("ouath_pkce_key")) {
        paramsObj["code_verifier"] = sessionStorage.getItem("ouath_pkce_key")
      }

      logDebug(paramsObj)
      const searchParams = new URLSearchParams(paramsObj)
      const url = pool?.DOMAIN + "/oauth2/token"
      logInfo("url", url)
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded"
      }
      logInfo("headers", headers)
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: searchParams.toString()
      })

      const result = await response.json()
      logDebug("exchangeCodeForToken result:", result)

      if (result.error) {
        throw new Error(result.error)
      }

      return result
}
  