import { CognitoJwtVerifier } from "aws-jwt-verify";
import { IJWT } from "../IJWT";
import { logDebug } from "src/log";
import { SimpleJwksCache } from "aws-jwt-verify/jwk";

export const parseJwt = (token: string): IJWT | undefined => {
    if (!token) return undefined 
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const extractPOOL_IDFromJWT = (jwt: IJWT) : string => {
    const parts = jwt.iss.split('/')
    const extractedString = parts[parts.length - 1]

    return extractedString
}

export const getClaimsFromIdToken = async (idToken: string): Promise<any> => {
    return ensureValidJWT(idToken)
}

const parseUserPoolFromJWT = (JWT: any): string => {
    const parts = JWT?.iss?.split("/")
  
    return parts[parts.length - 1]
}

export const jwksCache = new SimpleJwksCache()
  
export const ensureValidJWT = async (token: string): Promise<any> => {
    if (!token) return undefined
    const JWT = parseJwt(token)
    const userPool = parseUserPoolFromJWT(JWT)
    const clientId: string | null = JWT?.client_id || JWT?.aud || null
    const tokenUse: "id" | "access" | null = JWT?.token_use === "id" ? "id" : "access"
    if (!userPool) return undefined
        const cognitoVerifyProperties = {
        tokenUse,
        clientId,
        userPoolId: userPool
      };
    
      try {
        const verifier = CognitoJwtVerifier.create(cognitoVerifyProperties, { jwksCache });
        // Pass the token and the required options as separate arguments
        const payload = await verifier.verify(token, cognitoVerifyProperties);
        logDebug("Token is valid. Payload:", payload);
        return JWT;
      } catch (err) {
        console.error("Token verification failed", err);
        return undefined;
      }
}