export function inIFrame(): boolean {
    return ( window.location !== window.parent.location );
}

export const AllowedParentOrigin = (): string => {
    /*const toRemove = location.hostname.replace("sso", "")
    const app = parent.location.origin.replace(toRemove, "").toLowerCase()
    if (["maple","prunus","redwood","spruce"].includes(app)) {
        return parent.location.origin
    }*/
    return "*"
} 