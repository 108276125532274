import { logDebug } from "../log";

export let STORAGE: Storage = localStorage

export const setStorage = (s: Storage) => { STORAGE = s; logDebug("setStorage to", s) }

/* This storage ensures it is not persisted. Null Object pattern.
Used while mapping, we log into an e-ducate.me account to map the thirdparty user to. 
However afterwards we want to remain logged in the thirdparty and not be overwritten by the ad-hoc e-dcuate.me login. 
*/
export class MappingNullObjectStorage implements Storage {
  state: { [key: string]: string } = {}

  get length(): number {
    return Object.keys(this.state).length
  }
  
  clear(): void {
    this.state = {}
  }
  
  getItem(key: string): string | null {
    return this.state[key] as string
  }
  
  key(index: number): string | null {
    return Object.keys(this.state)[index] as string
  }
  
  removeItem(key: string): void {
    if(Object.keys(this.state).includes(key)) {
      delete this.state[key]
    }
  }
  
  setItem(key: string, value: string): void {
    if (this.state[key] === value) return 
    this.state[key] = value
  }
  
}