import { THIRD_PARTY_LOGOUT_URL_KEY } from "./app/logout/logout"
import { log, logDebug, logError } from "./log"
import { QS_CODE, QS_REDIRECT_URI, QS_RE_INIT, REDIRECT_URL_ON_STARTUP, onUnhandledError, qs } from "./main"
import { inIFrame } from "./app/inIFrame"
import { PARAMS } from "./app/PARAMS"
import { EVENTS } from "./app/EVENTS"

export const handleRedirectURI = (): void => {
    const url = qs.get(QS_REDIRECT_URI) || localStorage[REDIRECT_URL_ON_STARTUP]
    if (!inIFrame() && url && !["/logout", "/profile"].includes(location.pathname)) {
      if (localStorage[REDIRECT_URL_ON_STARTUP]) {
        localStorage.removeItem(REDIRECT_URL_ON_STARTUP)
      }
      location.href = url
    }
}

export const clearRedirectUriOnStartup = (): void => {
  localStorage.removeItem(REDIRECT_URL_ON_STARTUP)
} 
  
export const handleThirdPartyLogout = (): void => {
  if (inIFrame()) return 
  const logout_url = localStorage[THIRD_PARTY_LOGOUT_URL_KEY]
  if (logout_url) {
    localStorage.removeItem(THIRD_PARTY_LOGOUT_URL_KEY)
    location.href = logout_url
  }
}
  
export const reInitAmplify = (redirect_uri: string = "") => {
    const qParams = new URLSearchParams(location.search)
    if (qParams.has(QS_RE_INIT) && qParams.get(QS_RE_INIT) === "true") {
      // don't go into endless loops
      onUnhandledError()
      return 
    }
    qParams.append(QS_RE_INIT, "true")
    if (redirect_uri) {
      if (qParams.has(QS_REDIRECT_URI)) {
        qParams.delete(QS_REDIRECT_URI)
      }
      qParams.append(QS_REDIRECT_URI, redirect_uri)
    }
    if (qParams.has(QS_CODE)) qParams.delete(QS_CODE) // get rid off code or you'll get a free invalid_grant
    location.href = `${location.origin}?${qParams}` // don't loose redirect_url
}

export const getTokenRefreshInterval = () => {
  let refresh_interval = 60000 * 15
  const qs = new URLSearchParams(location.search)
  if (qs.has("refresh_interval")) {
    try {
      refresh_interval = Number(decodeURIComponent(qs.get("refresh_interval") as string))
      log(`applying refresh interval ${refresh_interval} ms.`)
    } catch(e) {
      logError("failed to parse refresh_interval from querystring", e)
    }
  } else {
    log(`applying default refresh interval ${refresh_interval} ms.`)
  }

  return refresh_interval
}
  
export const clearDynamoTokenCache = async (token: string) => {
  const body = JSON.stringify({ token })
  const response = await fetch(PARAMS.SSO_TOKEN_LAMBDA, { method: "DELETE", body })
  const result = await response.json()

  return result
}

export const isSafariOnIPadOrMac = (): boolean => {
  const userAgent = navigator.userAgent
  if (userAgent.includes('Safari') && !userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
      if (userAgent.includes('iPad')) {
        logDebug("Safari on Mac")
        return true // Safari on iPad
      } else if (userAgent.includes('Macintosh')) {
        logDebug("Safari on Macintosh")
        return true // Safari on Mac
      }
  }

  return false
}

export const isAndroid = (): boolean => {
  return navigator.userAgent.indexOf("Android") !== -1;
}

export const storeToCookie = (msg: any) => {
  const deepCopy = JSON.parse(JSON.stringify(msg))
  if (deepCopy.user?.publications) {
    deepCopy.user.publications = [] // publications can be too large to store in a cookie
  }
  for (let property in deepCopy) { // max cookies size 4 Kb split over multiple cookies
      let cookieValue = encodeURIComponent(JSON.stringify(deepCopy[property]))
      const cookieName = `${EVENTS.tokenChange}_${property}`
      let cookieString = `${cookieName}=${cookieValue};domain=.${location.host.split(".").slice(-2).join(".")};path=/;`
      document.cookie = cookieString
  }
}

export const deleteCookiesWithPrefix = (prefix = "tokenChange_") => {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Check if the cookie starts with the specified prefix
      if (cookie.indexOf(prefix) === 0) {
          const cookieName = cookie.split('=')[0]
          // Delete the cookie by setting its expiration date to the past and value to empty
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${location.host.split(".").slice(-2).join(".")};path=/;`
      }
  }
}

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const withTimeout = async (fn: Function, timeout = 2000) => {
  return Promise.race([
      fn(),
      new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout Error')), timeout))
  ])
}

