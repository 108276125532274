<div>
    <form class="centered-content amplifyBorders" data-amplify-form *ngIf="emailVerifyForm" data-amplify-form [formGroup]="emailVerifyForm" (ngSubmit)="verify()">
        <p>Er is een e-mail met een code naar jouw e-mailadres verstuurd. Vul de code hier in, alsjeblieft.</p>
        <div class="form-group" >
            <label class="amplify-label" for="code">Email verificatie Code</label>
            <input type="text" class="amplify-input" id="code" name="code" formControlName="code">    
        </div>
        <div class="form-group buttons">
            <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" type="submit" >Verifieer</button>
        </div>
    </form>
</div>
